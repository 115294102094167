import { useDispatch, useSelector } from "react-redux"
import { setLang } from "../../redux"

import "./style.css"

import flag_fr from './../../assets/img/flag_fr.png'
import flag_en from './../../assets/img/flag_en.png'
import flag_sp from './../../assets/img/flag_sp.png'
import flag_de from './../../assets/img/flag_de.png'

function ModalSelector() {
    const dispacth = useDispatch();
    const lang = useSelector(state => state.user.lang);

    return (
        <ul className='lang_selector'>
            <li>
                <button className={lang === 'fr' ? 'actif' : ''} onClick={() => dispacth(setLang('fr'))}>
                    <img src={flag_fr} alt=" " />
                </button>
            </li>

            <li>
                <button className={lang === 'en' ? 'actif' : ''} onClick={() => dispacth(setLang('en'))}>
                    <img src={flag_en} alt=" " />
                </button>
            </li>
            
            <li>
                <button className={lang === 'sp' ? 'actif' : ''} onClick={() => dispacth(setLang('sp'))}>
                    <img src={flag_sp} alt=" " />
                </button>
            </li>
            
            <li>
                <button className={lang === 'de' ? 'actif' : ''} onClick={() => dispacth(setLang('de'))}>
                    <img src={flag_de} alt=" " />
                </button>
            </li>
        </ul>
    )
}

export default ModalSelector;