import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, setLoader } from '../../redux';

import Lang from './../../selectors/Lang';

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function DeleteCategories() {
    const dispatch = useDispatch();

    const [alert, setAlert] = useState(null);
    const [toDelete, setToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [parent, setParent] = useState('');
    const [categories, setCategories] = useState([]);

    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);
    const products_db = useSelector(state => state.data.products);
    const services_db = useSelector(state => state.data.services);
    const achievments_db = useSelector(state => state.data.achievments);


    // Mise à jour affichage des éléments
    useEffect(() => {
        if (!categories_db) return;

        const categories_list = categories_db.filter(cat => !cat.parentId);
        setCategories(categories_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mise à jour affichage des éléments
    useEffect(() => {
        if ((!parent && parent !== '') || !categories_db) return;

        if (parent === '') {
            const categories_list = categories_db.filter(cat => !cat.parentId);
            setCategories(categories_list);
            return;
        }

        const categories_list = categories_db.filter(cat => cat.parentId === parent);
        setCategories(categories_list);

    }, [parent, categories_db]);


    const previous = () => {
        const cat_parent = categories_db.find(cat => cat._id === parent);
        setParent(cat_parent && cat_parent.parentId ? cat_parent.parentId : '');
    };

    const deleteCategory = async () => {
        const secr = true;
        if (secr) {
            if (categories_db.find(e => e.parentId === toDelete._id)) {
                setAlert({
                    title: `Imposible de supprimer cette catégorie`,
                    message: `Vous ne pouvez pas supprimer cette catégorie car elle possede une ou plusieurs sous-catégorie(s)`
                });
                setToDelete(null);
                return
            }
    
            if (products_db.find(e => e.parentId === toDelete._id)) {
                setAlert({
                    title: `Imposible de supprimer cette catégorie`,
                    message: `Vous ne pouvez pas supprimer cette catégorie car elle possede un ou plusieurs produit(s)`
                });
                setToDelete(null);
                return
            }
    
            if (services_db.find(e => e.parentId === toDelete._id)) {
                setAlert({
                    title: `Imposible de supprimer cette catégorie`,
                    message: `Vous ne pouvez pas supprimer cette catégorie car elle possede un ou plusieurs service(s)`
                });
                setToDelete(null);
                return
            }
    
            if (achievments_db.find(e => e.parentId === toDelete._id)) {
                setAlert({
                    title: `Imposible de supprimer cette catégorie`,
                    message: `Vous ne pouvez pas supprimer cette catégorie car elle possede une ou plusieurs réalisation(s)`
                });
                setToDelete(null);
                return
            }
        }

        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/category/${toDelete._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur s\'est produite',
                message: res.message
            });
            return;
        }
        
        // Erreur serveur
        if (req.status !== 200 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return;
        }

        // Mise à jour de la state 'categories'
        const new_categories_db = categories_db.filter(cat => cat._id !== toDelete._id);
        dispatch(addCategoriesToState(new_categories_db));

        setAlert({
            title: 'Catégorie supprimé',
            message: 'Votre catégorie a bien été supprimé de la base de donnée'
        });

        setToDelete(null);
        setShowDeleteModal(false);
    };


    return (
        <div className='modal modal_delete_categories'>
            <Lang />

            <h1>Supprimer une catégorie</h1>

            <form>
                <div className='category_selector'>
                    <h2>Liste des catégories :</h2>

                    <ul className="select_list">
                        {parent !== '' &&
                            <li className='first'>
                                <button type='button' onClick={() => previous()} >Revenir en arrière</button>
                            </li>
                        }

                        {categories && categories.length !== 0 ? categories.map((cat, idx) =>
                            <li
                                key={idx}
                                className={parent === '' ? 'first' : ''}
                            >
                                <button type='button'>
                                    <p onClick={() => setParent(cat._id)}>{cat.name}</p>

                                    <img
                                        onClick={() => {
                                            setToDelete(cat);
                                            setShowDeleteModal(true);
                                        }}
                                        className='btn_delete'

                                        src={trash}
                                        alt=" "
                                    />
                                </button>
                            </li>
                        ) : (
                            <li>
                                <p className='no_value'>Aucune données</p>
                            </li>
                        )}
                    </ul>

                    {showDeleteModal && toDelete &&
                        <div className="mdl_cancel_update">
                            <div className="content">
                                <span>Demande de suppression</span>
                                <p>Vous vous apprêtez à supprimer "{toDelete.name}". <br /><br /> Êtes-vous sûre ?</p>

                                <div className="panel">
                                    <button type='button' onClick={() => {
                                        setToDelete(null);
                                        setShowDeleteModal(false);
                                    }}>non</button>
                                    <button type='button' onClick={() => deleteCategory()}>oui</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </form>

            {alert &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DeleteCategories;