import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsToState, setLoader } from '../../redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';

import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import BenefitsAndFeatures from '../../components/BenefitsAndFeatures';
import References from '../../components/References';
import SelecteAssociated from '../../selectors/Associated';
import CategorySelector from '../../selectors/Category';
import PanelControl from '../../components/PanelControl';

import "./style.css"

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {
    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    benefits_fr: Array(8).fill(''),
    benefits_en: Array(8).fill(''),
    benefits_sp: Array(8).fill(''),
    benefits_de: Array(8).fill(''),

    features_fr: Array(8).fill(''),
    features_en: Array(8).fill(''),
    features_sp: Array(8).fill(''),
    features_de: Array(8).fill(''),

    references: [],
    ref_descrip_fr: '',
    ref_descrip_en: '',
    ref_descrip_sp: '',
    ref_descrip_de: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    associated: [],
    parentId: ''
}

function CreateProducts() {
    const dispatch = useDispatch();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState(null);
    const [part, setPart] = useState(1);


    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom du produit" est requis au bon fonctionnement de votre site'
            });
            return
        }

        if (formData.parentId === '') {
            setAlert({
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            });
            return
        }

        await postProduct();
    }

    const postProduct = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,
            
            images: [],

            associated: formData.associated,
            references: formData.references,
            ref_descrip_fr: formData.ref_descrip_fr || '',
            ref_descrip_en: formData.ref_descrip_en || '',
            ref_descrip_sp: formData.ref_descrip_sp || '',
            ref_descrip_de: formData.ref_descrip_de || '',

            parentId: formData.parentId,
            online: false,
        }

        // Si il y a des 'images'
        body.images = await postImages();
        if (body.images === 'error') {
            dispatch(setLoader(-1));
            return
        }

        //Si il y a des 'files'
        body.files = await postFiles();
        if (body.files === 'error') {
            dispatch(setLoader(-1));
            return
        }

        // Filtrage des liste 'benefits' & 'features'
        for (let lang of langs) {
            body[`benefits_${lang}`] = formData[`benefits_${lang}`].filter(e => e !== '' && e !== ' ');
            body[`features_${lang}`] = formData[`features_${lang}`].filter(e => e !== '' && e !== ' ');
        }

        // Ajoute le produit
        const req = await fetch(`https://api.pro-vide.eu/admin/product`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de l\'ajout du produit',
            message: res.message
        });

        // Erreur serveur
        if (req.status !== 201 || !res._id) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        // Mise à jour de la state 'products'
        const new_products_db = [...products_db, { ...res, name: res.name_fr }];
        dispatch(addProductsToState(new_products_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form, parentId: formData.parentId });
        setAlert({
            title: 'Produit ajouté',
            message: 'Votre produit a bien été ajouté à la base de donnée'
        });
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            // Création du formulaire d'image
            const formImage = new FormData();
            formImage.append('image', img.value);

            // Ajout de l'image à la db
            const image = await postImage(setAlert, formImage);

            // Si l'image n'est pas ajouté on stop
            if (!image) {

                // Suppression des images précédente
                for (let _id of response) {
                    const img_delete = await deleteImage(_id, setAlert);

                    // Si erreur lors de la suppression des images
                    if (!img_delete) {
                        alertMsg('Erreur multiple, contactez le support du site');
                        return 'error'
                    }
                }
                return 'error'
            }

            // Ajout de 'image._id' à 'images' dans le corp de la requete
            response.push(image);
        }

        return response
    }

    const postFiles = async () => {
        let response = {
            fr: [],
            en: [],
            sp: [],
            de: []
        };

        for (let lang of langs) {
            for (let file of formData.files[lang]) {

                // Création du formulaire de fichier
                const formFile = new FormData();
                formFile.append('file', file);

                // Ajout du fichier
                const file_id = await postFile(setAlert, formFile);
                response[lang].push(file_id);

                // Si le fichier n'est pas ajouté
                if (!file_id) {
                    // Suppréssion de tous les fichiers ajouter précédement
                    await deleteLastFiles(response, lang);
                    return 'error'
                }
            }
        }
        return response
    }

    // Suppression des fichiers précédent
    async function deleteLastFiles(response, lastLang) {
        let lock = false;

        // Pour chaque lang
        for (let i = 0; i < langs.length; i++) {
            const lang = langs[i];

            // Si la boucle est finit
            if (lock) continue

            // Pour chaque list de fichier à supprimer
            for (let j = 0; j < response[lang].length; j++) {

                // Si c'est le dernier fichier de la derniere list de fichier à supprimer, on verrouille
                if (j === response[lang].length && lang === lastLang) lock = true;

                const _id = response[lang][j];

                const file_delete = await deleteFile(_id, setAlert);

                // Si erreur lors de la suppression des fichiers
                if (!file_delete) {
                    alertMsg('Erreur multiple, contactez le support du site');
                    return
                }
            }
        }
    }


    return formData && part && (
        <div className='modal modal_create_products'>
            <Lang />

            <h1>Créer un produit</h1>

            <form onSubmit={(event) => submitForm(event)}>
                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='du produit' />
                        <InputImages formData={formData} setFormData={setFormData} placeholder='du produit' />
                    </div>
                }

                {part === 2 && <BenefitsAndFeatures formData={formData} setFormData={setFormData} />}

                {part === 3 && <SelecteAssociated formData={formData} setFormData={setFormData} />}

                {part === 4 && <References formData={formData} setFormData={setFormData} />}

                {part === 5 && <CategorySelector formData={formData} setFormData={setFormData} placeholder='du produit' type='prod' />}

                <PanelControl part={part} setPart={setPart} maxPart={5} btnValue='Ajouter le produit' />
            </form>

            {alertMsg &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateProducts;