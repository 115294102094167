import "./style.css"

function ModalCategories({ process, setProcess }) {
    return (
        <div className='modal modal_categories'>
            
        </div>
    )
}

export default ModalCategories;