import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReferencesToState, setLoader } from '../../redux';

import { useFetchFile } from '../../hooks/useFetchFile';
import { useFetchImage } from '../../hooks/useFetchImage';
import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import "./style.css";

import trash from './../../assets/svg/svg_trash_red.svg';

const init_form = {
    head: ['References', '3D-plan'],
    references: []
};

function DeleteReferences() {
    const dispatch = useDispatch();
    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();
    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const references_db = useSelector(state => state.data.references);

    const [search, setSearch] = useState('');
    const [searchList, setSearchList] = useState([]);

    const [formData, setFormData] = useState(init_form);
    const [alert, setAlert] = useState(null);


    useEffect(() => {
        let new_list = [];

        if (!search || search === '') {
            setSearchList(new_list);
            return
        }

        // Trouver les references.table.References qui contienne search
        new_list = references_db.filter(ref =>
            ref.table && ref.table.References && typeof ref.table.References === 'string' &&
            ref.table.References.toLowerCase().includes(search.toLowerCase())
        );

        // Limiter le nombre de résultats à 16
        new_list = new_list.slice(0, 16);

        setSearchList(new_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [references_db, search])


    const addRef = async (ref) => {
        const check = formData.references.find(_ => _._id === ref._id);
        if (check) return

        const new_head = Object.keys(ref.table).filter(head => !formData.head.includes(head));
        let new_ref = { ...ref.table, _id: ref._id };

        if (new_ref['3D-plan'] !== '') {
            const img = await fetchImage(new_ref['3D-plan']);
            const file = await fetchFile(new_ref['3D-plan']);

            if (img) new_ref['3D-plan'] = img;
            else if (file) new_ref['3D-plan'] = file;
        }

        setFormData(prevFormData => ({
            head: [...prevFormData.head, ...new_head],
            references: [...prevFormData.references, { ...new_ref }]
        }));
    }

    const submitForm = async (event) => {
        event.preventDefault();
    };

    const deleteReference = async (ref) => {
        dispatch(setLoader(1));

        if (ref['3D-plan']._id) {
            if (ref['3D-plan'].image) {
                const file_delete = await deleteImage(ref['3D-plan']._id, setAlert);
                if (!file_delete) {
                    setAlert({
                        title: `Impossible de supprimer le schema`,
                        message: `Impossible de supprimer le schema ainsi que la référence, merci de contactez le support.`
                    });
                    dispatch(setLoader(-1));
                    return
                }
            }
            else if (ref['3D-plan'].file) {
                const file_delete = await deleteFile(ref['3D-plan']._id, setAlert);
                if (!file_delete) {
                    setAlert({
                        title: `Impossible de supprimer le schema`,
                        message: `Impossible de supprimer le schema ainsi que la référence, merci de contactez le support.`
                    });
                    dispatch(setLoader(-1));
                    return
                }
            }
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/reference/${ref._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 400];

        dispatch(setLoader(-1));
        
        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur s\'est produite',
                message: res.message
            });
            return;
        }

        // Erreur serveur
        if (req.status !== 200 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return;
        }

        // Mise à jour de la state 'references'
        const new_references_db = references_db.filter(e => e._id !== ref._id);
        dispatch(addReferencesToState(new_references_db));

        setAlert({
            title: 'Référence supprimé',
            message: 'Votre référence a bien été supprimé de la base de donnée'
        });
    };

    const formatFilename = (name) => {

        // Supprime tout ce qui se trouve entre 'https' et 'plans/'
        const regex1 = /https.*?plans\//;
        let result = name.replace(regex1, '');

        // Supprime tout ce qui se trouve entre 'https' et 'images/'
        const regex2 = /https.*?images\//;
        result = result.replace(regex2, '');

        // Extrait l'extension du fichier
        const regexExtension = /\.([^.]+)$/;
        const match = result.match(regexExtension);
        const extension = match ? match[1] : '';

        // Supprime les numéros aléatoires à la fin du nom de fichier
        if (extension) {
            const regex2 = new RegExp(`-\\d+\\.${extension}$`);
            result = result.replace(regex2, `.${extension}`);
        }

        return result;
    }


    return formData && (
        <div className='modal modal_update_references'>
            <h1>Modifier une référence</h1>

            <form onSubmit={(event) => submitForm(event)}>
                <div className="ctn_search">
                    <div className="ctn_search_bar">
                        <h2>Références :</h2>

                        <div className="search_bar">
                            <input
                                type="text"
                                value={search}

                                placeholder="Recherche par nom : 'KF16SVCR'"
                                onChange={(e) => setSearch(e.target.value)}
                            />

                            <i className="fas fa-magnifying-glass"></i>
                        </div>

                        {searchList.length !== 0 &&
                            <ul className="search_list">
                                {searchList.map((ref, idx) =>
                                    <li key={idx}>
                                        <p>{ref.table.References}</p>

                                        <button
                                            type='button'
                                            onClick={() => addRef(ref)}
                                        >
                                            <i className="fas fa-share"></i>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                </div>


                <div className="ctn_table_ref">
                    <h2>Créer plusieurs références à la fois :</h2>

                    <div className="table_ref">
                        <ul>
                            {formData.head.map((head, idx) => (
                                <li key={idx}>
                                    <p>{head}</p>
                                </li>
                            ))}
                        </ul>

                        {formData.references.map((ref, idx) => (
                            <ul key={idx}>
                                {formData.head.map((head, id) => (
                                    <li key={id}>
                                        {head !== '3D-plan' &&
                                            <p>{ref[head]}</p>
                                        }

                                        {head === '3D-plan' &&
                                            <div className="input_schema">
                                                {ref[head]?.image && <label>{formatFilename(ref[head]?.image)}</label>}
                                                {ref[head]?.file && <label>{formatFilename(ref[head]?.file)}</label>}
                                            </div>
                                        }

                                        {head === 'References' && (
                                            <button
                                                type='button'
                                                className='btn_del_row'
                                                onClick={() => deleteReference(ref)}
                                            >
                                                <img src={trash} alt=" " />
                                            </button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>
            </form>

            {alert && (
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DeleteReferences;