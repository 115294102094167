import "./style.css"

function ShowAchievments() {

    return (
        <div className='modal modal_show_achievments'>
            <h1>Changer l'ordre des réalisations</h1>

            <form>

            </form>
        </div>
    )
}

export default ShowAchievments;