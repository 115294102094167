import { addAchievmentsToState, addCategoriesToState, addProductsToState, addReferencesToState, addServicesToState, login, setLang } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { useGoPage } from "../../hooks/useGoPage";
import { useGetStorage } from "../../hooks/useGetStorage";
import { useGetAllData } from "../../hooks/useGetAllData";

import "./style.css"

import logo from './../../assets/img/logo-pro-vide.png';
import icon_dashboard from './../../assets/svg/icon_dashboard.svg';
import icon_categories from './../../assets/svg/icon_categories.svg';
import icon_products from './../../assets/svg/icon_products.svg';
import icon_services from './../../assets/svg/icon_services.svg';
import icon_achievments from './../../assets/svg/icon_achievments.svg';

function Header({ process, setProcess }) {
    const goPage = useGoPage();
    const dispatch = useDispatch();
    const location = useLocation();

    const getStorage = useGetStorage();
    const getAllData = useGetAllData();

    const user = useSelector(state => state.user);


    // Verification de la session et récupération des données
    useEffect(() => {
        const fechAllData = async () => {
            const all_data = await getAllData();

            dispatch(addCategoriesToState(all_data.categories));
            dispatch(addProductsToState(all_data.products));
            dispatch(addReferencesToState(all_data.references));
            dispatch(addServicesToState(all_data.services));
            dispatch(addAchievmentsToState(all_data.achievements));
        }

        // Si session active
        if (user && user.admin_id !== '' && user.admin_pw !== '' && user.token !== '') fechAllData()

        // Récupération du storage
        const storage = getStorage();

        // Si aucune session
        if (!storage.local && !storage.session) goPage('login');

        // Cache sessionStorage
        if (storage.session) {
            dispatch(login({
                admin_id: sessionStorage.getItem('admin_id'),
                admin_pw: sessionStorage.getItem('admin_pw'),
                token: sessionStorage.getItem('token')
            }));
            return
        }

        // Cache localStorage
        if (storage.local) {
            dispatch(login({
                admin_id: localStorage.getItem('admin_id'),
                admin_pw: localStorage.getItem('admin_pw'),
                token: localStorage.getItem('token')
            }));
            sessionStorage.setItem('admin_id', localStorage.getItem('admin_id'))
            sessionStorage.setItem('admin_pw', localStorage.getItem('admin_pw'))
            sessionStorage.setItem('token', localStorage.getItem('token'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Gestion process
    useEffect(() => {
        if (!process) return

        const init_process = {
            w_modal: 'selector',
            modal: 'selector',
            page: '',

            w_stop: false,
            stop: false
        }

        // Changement de modal sans besoin de validation
        if (!process.w_stop && (process.modal === 'selector' || process.modal === 'show') && process.w_modal !== process.modal) {
            dispatch(setLang('fr'));
            setProcess({
                ...process,
                modal: process.w_modal
            });
            return
        };

        // Changement de page sans besoin de validation
        if (!process.w_stop && (process.modal === 'selector' || process.modal === 'show') && process.page !== '') {
            dispatch(setLang('fr'));
            goPage(process.page)
            setProcess(init_process);
            return
        };

        // Validation
        if (process.stop && process.page !== '') {
            dispatch(setLang('fr'));
            goPage(process.page);
            setProcess(init_process);
            return
        };

        if (process.stop && process.w_modal !== process.modal) {
            dispatch(setLang('fr'));
            setProcess({
                ...process,
                modal: process.w_modal,
                w_stop: false,
                stop: false
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process])


    // Demande de changement de page
    const changePage = (path) => {
        setProcess({
            ...process,
            w_stop: process.modal !== 'selector' && process.modal !== 'show' ? true : false,
            page: path,
        });
    }


    return location && (
        <header>
            <button
                className='btn_home'
                onClick={() => changePage('/')}
            ><img src={logo} alt=" " /></button>

            <ul>
                <li>
                    <button
                        onClick={() => changePage('/')}
                        className={location.pathname === '/' ? 'actif' : ''}
                    ><img src={icon_dashboard} alt=" " />Tableau de bord</button>
                </li>
                <li>
                    <button
                        onClick={() => changePage('/categories')}
                        className={location.pathname === '/categories' ? 'actif' : ''}
                    ><img src={icon_categories} alt=" " />Catégories</button>
                </li>
                <li>
                    <button
                        onClick={() => changePage('/products')}
                        className={location.pathname === '/products' ? 'actif' : ''}
                    ><img src={icon_products} alt=" " />Produits</button>
                </li>
                <li>
                    <button
                        onClick={() => changePage('/references')}
                        className={location.pathname === '/references' ? 'actif' : ''}
                    ><img src={icon_products} alt=" " />Références</button>
                </li>
                <li>
                    <button
                        onClick={() => changePage('/services')}
                        className={location.pathname === '/services' ? 'actif' : ''}
                    ><img src={icon_services} alt=" " />Services</button>
                </li>
                <li>
                    <button
                        onClick={() => changePage('/achievments')}
                        className={location.pathname === '/achievments' ? 'actif' : ''}
                    ><img src={icon_achievments} alt=" " />Réalisations</button>
                </li>
            </ul>
        </header>
    )
}

export default Header;