import "./style.css"

function ModalSelector({ process, setProcess, page, un }) {
    return (
        <ul className='modal_selector'>
            <li
                className={process.modal === 'selector' ? 'actif' : ''}
                onClick={() => process.modal !== 'selector' && setProcess(prev => ({ 
                    ...prev, 
                    w_modal: 'selector' ,
                    w_stop: (process.modal === 'selector' || process.modal === 'show') ? false : true, 
                }))}
            >Liste des {page}s</li>
            <li
                className={process.modal === 'add' ? 'actif' : ''}
                onClick={() => process.modal !== 'add' && setProcess(prev => ({ 
                    ...prev, 
                    w_modal: 'add' ,
                    w_stop: (process.modal === 'selector' || process.modal === 'show') ? false : true, 
                }))}
            >Ajouter {un} {page}</li>
            <li
                className={process.modal === 'edit' ? 'actif' : ''}
                onClick={() => process.modal !== 'edit' && setProcess(prev => ({ 
                    ...prev, 
                    w_modal: 'edit' ,
                    w_stop: (process.modal === 'selector' || process.modal === 'show') ? false : true, 
                }))}
            >Modifier {un} {page}</li>
            <li
                className={process.modal === 'delete' ? 'actif' : ''}
                onClick={() => process.modal !== 'delete' && setProcess(prev => ({ 
                    ...prev, 
                    w_modal: 'delete' ,
                    w_stop: (process.modal === 'selector' || process.modal === 'show') ? false : true, 
                }))}
            >Supprimer {un} {page}</li>
            <li
                className={process.modal === 'show' ? 'actif' : ''}
                onClick={() => process.modal !== 'show' && setProcess(prev => ({ 
                    ...prev, 
                    w_modal: 'show' ,
                    w_stop: (process.modal === 'selector' || process.modal === 'show') ? false : true, 
                }))}
            >Changer l'ordre des {page}s</li>
        </ul>
    )
}

export default ModalSelector;