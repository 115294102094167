import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css"

function CategorySelector({ formData, setFormData, placeholder, type, lock }) {
    const [parent, setParent] = useState('');
    const [categories, setCategories] = useState([]);

    const categories_db = useSelector(state => state.data.categories);

    // Mise à jour affichage des catégories
    useEffect(() => {
        if (!formData || !categories_db) return

        const categories_list = categories_db.filter(cat => type ? type === cat.type && !cat.parentId : !cat.parentId);
        setCategories(categories_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mise à jour affichage des catégories
    useEffect(() => {
        if ((!parent && parent !== '') || !categories_db) return

        if (parent === '') {
            const categories_list = categories_db.filter(cat => type ? type === cat.type && !cat.parentId : !cat.parentId);
            setCategories(categories_list);
            return
        }

        const categories_list = categories_db.filter(cat => cat.parentId === parent);
        setCategories(categories_list);

    }, [parent, categories_db, type]);

    const previous = () => {
        const cat_parent = categories_db.find(cat => cat._id === parent);
        setParent(cat_parent.parentId || '');
    }

    const chooseParent = (_id) => {
        setFormData({
            ...formData,
            parentId: _id === formData.parentId ? '' : _id
        })
    }

    return (
        <div className='category_selector'>
            <h2>Localisation {placeholder}</h2>

            <ul className="select_list">
                {parent !== '' &&
                    <li className='first'>
                        <button type='button' onClick={() => previous()} >Revenir en arrière</button>
                    </li>
                }

                {categories && categories.length !== 0 ? categories.map((cat, idx) =>
                    <li 
                        key={idx}
                        className={parent === '' ? 'first' : ''}
                    >
                        <button
                            type='button'
                            className={cat._id === formData.parentId ? 'actif' : ''}
                        >
                            <p onClick={() => !lock && setParent(cat._id)}>{cat.name}</p>
                            <i
                                className='check'
                                onClick={() => chooseParent(cat._id)}
                            >{cat._id === formData.parentId ? '✓' : ''}</i>
                        </button>
                    </li>
                ) : (
                    <li>
                        <p className='no_value'>Aucune données</p>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default CategorySelector;