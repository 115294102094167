import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addServicesToState, setLoader } from '../../redux';

import Lang from './../../selectors/Lang';

import trash from './../../assets/svg/svg_trash_red.svg';

import "./style.css";

function DeleteServices() {
    const dispatch = useDispatch();

    const [alert, setAlert] = useState(null);
    const [toDelete, setToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [parent, setParent] = useState('');
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);

    const token = useSelector(state => state.user.token);
    const services_db = useSelector(state => state.data.services);
    const categories_db = useSelector(state => state.data.categories);


    // Mise à jour affichage des éléments
    useEffect(() => {
        if (!categories_db) return;

        const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'serv');
        setCategories(categories_list);
    }, [categories_db]);

    // Mise à jour affichage des éléments
    useEffect(() => {
        if ((!parent && parent !== '') || !categories_db) return;

        if (parent === '') {
            const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'serv');
            setCategories(categories_list);
            setServices([]);
            return;
        }

        const categories_list = categories_db.filter(cat => cat.parentId === parent);
        setCategories(categories_list);

        if (!categories_list || categories_list.length === 0) {
            const services_list = services_db.filter(prod => prod.parentId === parent);
            setServices(services_list);
        }
    }, [parent, categories_db, services_db]);


    const previous = () => {
        const cat_parent = categories_db.find(cat => cat._id === parent);
        setParent(cat_parent && cat_parent.parentId ? cat_parent.parentId : '');
        setServices([]);
    };

    const deleteProduct = async () => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/service/${toDelete._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 400];

        dispatch(setLoader(-1));
        
        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur s\'est produite',
                message: res.message
            });
            return;
        }

        // Erreur serveur
        if (req.status !== 200 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return;
        }

        // Mise à jour de la state 'services'
        const new_services_db = services_db.filter(prod => prod._id !== toDelete._id);
        dispatch(addServicesToState(new_services_db));

        setAlert({
            title: 'Service supprimé',
            message: 'Votre service a bien été supprimé de la base de donnée'
        });

        setToDelete(null);
        setShowDeleteModal(false);
    };

    
    return (
        <div className='modal modal_delete_services'>
            <Lang />

            <h1>Supprimer un service</h1>

            <form>
                <div className='service_selector'>
                    <h2>Liste des services :</h2>

                    <ul className="select_list">
                        {parent !== '' &&
                            <li className='first'>
                                <button type='button' onClick={() => previous()} >Revenir en arrière</button>
                            </li>
                        }

                        {categories && categories.length !== 0 && categories.map((cat, idx) =>
                            <li
                                key={idx}
                                className={parent === '' ? 'first' : ''}
                            >
                                <button type='button'>
                                    <p onClick={() => setParent(cat._id)}>{cat.name}</p>
                                </button>
                            </li>
                        )}

                        {services && services.length !== 0 && services.map((prod, idx) =>
                            <li key={idx} className='product'>
                                <button type='button'>
                                    <p>{prod.name}</p>

                                    <img
                                        onClick={() => {
                                            setToDelete(prod);
                                            setShowDeleteModal(true);
                                        }}
                                        className='btn_delete'
                                        src={trash}
                                        alt=" "
                                    />
                                </button>
                            </li>
                        )}

                        {categories.length === 0 && services.length === 0 &&
                            <li>
                                <p className='no_value'>Aucune données</p>
                            </li>
                        }
                    </ul>

                    {showDeleteModal && toDelete &&
                        <div className="mdl_cancel_update">
                            <div className="content">
                                <span>Demande de suppression</span>
                                <p>Vous vous apprêtez à supprimer "{toDelete.name}". <br /><br /> Êtes-vous sûre ?</p>

                                <div className="panel">
                                    <button type='button' onClick={() => {
                                        setToDelete(null);
                                        setShowDeleteModal(false);
                                    }}>non</button>
                                    <button type='button' onClick={() => deleteProduct()}>oui</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </form>

            {alert &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DeleteServices;