import { useDispatch } from "react-redux";
import { login, setLoader } from "../redux";

export const usePostLogin = () => {
    const dispatch = useDispatch();

    const postLogin = async (admin_id, admin_pw, setMsgError) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ admin_id, admin_pw })
        });
        const res = await req.json();
        
        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) setMsgError(res.message);

        // Erreur serveur
        if (req.status === 500) setMsgError('Probleme serveur, merci de votre comprehension');

        // Fin du message d'erreur
        if (req.status !== 200) {
            setTimeout(() => setMsgError(''), 4000);
            return false
        }

        sessionStorage.setItem('admin_id', admin_id);
        sessionStorage.setItem('admin_pw', admin_pw);
        sessionStorage.setItem('token', res.token);

        dispatch(login({ 
            admin_id: admin_id, 
            admin_pw: admin_pw, 
            token: res.token 
        }));

        return true
    };

    return postLogin;
};
