// import { useIsNumeric } from "../../hooks/useIsNumeric";

import "./style.css"

function InputList({ placeholder, formData, setFormData, idx, field }) {
    const updtValue = (e) => {
        let newList = formData[field];
        newList[idx] = e.target.value;

        setFormData({
            ...formData,
            [field]: newList
        })
    }

    return (
        <li className="input_list">
            <input
                type="text"
                placeholder={placeholder}

                value={formData[field][idx]}
                onChange={e => updtValue(e)}
            />
        </li>
    )
}

export default InputList;