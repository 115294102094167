import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePostFile } from '../../hooks/usePostFile';
import { useFetchFile } from '../../hooks/useFetchFile';
import { usePostImage } from '../../hooks/usePostImage';
import { useFetchImage } from '../../hooks/useFetchImage';
import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import "./style.css";

import trash from './../../assets/svg/svg_trash_red.svg';
import { addReferencesToState, setLoader } from '../../redux';

const init_form = {
    head: ['References', '3D-plan'],
    references: []
};

function UpdateReferences() {
    const dispatch = useDispatch();
    const postFile = usePostFile();
    const postImage = usePostImage();
    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();
    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const references_db = useSelector(state => state.data.references);

    const [search, setSearch] = useState('');
    const [searchList, setSearchList] = useState([]);

    const [formData, setFormData] = useState(init_form);
    const [lastData, setLastData] = useState([]);
    const [alert, setAlert] = useState(null);


    useEffect(() => {
        let new_list = [];

        if (!search || search === '') {
            setSearchList(new_list);
            return
        }

        // Trouver les references.table.References qui contienne search
        new_list = references_db.filter(ref =>
            ref.table && ref.table.References && typeof ref.table.References === 'string' &&
            ref.table.References.toLowerCase().includes(search.toLowerCase())
        );

        // Limiter le nombre de résultats à 16
        new_list = new_list.slice(0, 16);

        setSearchList(new_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [references_db, search])


    const handleHead = (e, idx) => {
        const value = e.target.value;

        if (value === 'References' || value === '3D-plan') return;

        let new_form = { ...formData };
        new_form.head[idx] = value;

        setFormData(new_form);
    };

    const handleValue = (e, id, field) => {
        let new_form = { ...formData };
        new_form.references[id][field] = e.target.value;

        setFormData(new_form);
    };

    const handleSchema = (e, id, field) => {
        const file = e.target.files[0];
        let new_form = { ...formData };

        new_form.references[id][field] = file;
        setFormData(new_form);
    };

    const delSchema = (idx, head) => {
        let new_form = { ...formData };
        new_form.references[idx][head] = '';
        setFormData(new_form);
    };


    const addRow = () => {
        let new_form = { ...formData };
        let new_row = {};

        formData.head.forEach(head => new_row[head] = '');
        new_form.references.push(new_row);

        setFormData(new_form);
    };

    const addColumn = () => {
        let new_form = {
            head: [...formData.head, ''],
            references: [
                ...formData.references
            ]
        };

        for (let i = 0; i < new_form.references.length; i++) {
            new_form.references[i] = { ...new_form.references[i], '': '' };
        }

        setFormData(new_form);
    };

    const delColumn = (head) => {
        const new_form = {
            head: formData.head.filter(e => e !== head),
            references: formData.references.map(ref => {
                const { [head]: _, ...rest } = ref;
                return rest;
            }),
        };

        setFormData(new_form);
    };

    const addRef = async (ref) => {
        const check = formData.references.find(_ => _._id === ref._id);
        if (check) return

        const new_head = Object.keys(ref.table).filter(head => !formData.head.includes(head));
        let new_ref = { ...ref.table, _id: ref._id };

        if (new_ref['3D-plan'] !== '') {
            const img = await fetchImage(new_ref['3D-plan']);
            const file = await fetchFile(new_ref['3D-plan']);

            if (img) new_ref['3D-plan'] = img;
            else if (file) new_ref['3D-plan'] = file;
        }

        setLastData(prevLastData => [...prevLastData, {...new_ref}]);
        setFormData(prevFormData => ({
            head: [...prevFormData.head, ...new_head],
            references: [...prevFormData.references, {...new_ref}]
        }));
    }


    const delRef = (idx) => {
        const newReferences = formData.references.filter((_, i) => i !== idx);
        setFormData({ ...formData, references: newReferences });
        setLastData([...newReferences])
    }


    const isValidFileExtension = (file) => {
        const validExtensions = ['.pdf', '.step', '.dwg', '.dxf', '.igs'];
        const extension = validExtensions.find(ext => file.name.toLowerCase().endsWith(ext));

        return !!extension;
    };

    const isValidImgExtension = (file) => {
        const validExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        const response = validExtensions.includes(file.type);

        return response
    };


    const submitForm = async (event) => {
        event.preventDefault();
        dispatch(setLoader(1));

        const response = await Promise.all(formData.references.map(ref => updateReference(ref)));

        let new_form = [];

        for (let i = 0; i < response.length; i++) {
            if (!response[i]) new_form.push(formData.references[i]);
        }

        setFormData({ ...formData, references: new_form });

        dispatch(setLoader(-1));
        
        if (new_form.includes(true)) setAlert({
            title: 'Référence(s) modifié(s)',
            message: 'La ou les référence(s) on bien été modifié à la base de donnée'
        });

        if (new_form.includes(false)) setAlert({
            title: 'Référence(s) NON modifié(s)',
            message: 'Une ou plusieurs référence(s) n\'on pas été modifié'
        });
    };

    const updateReference = async (reference) => {
        let new_reference = {};

        const heads = formData.head.filter(head => head !== '' && head !== '');

        if (reference.References === '' || reference.References === ' ') return null

        heads.map(head => new_reference[head] = reference[head]);

        // Update reference
        if (reference._id) {
            const last_ref = lastData.find(ref => ref._id === reference._id);

            // Si le schema reste inchangé
            if (new_reference['3D-plan']._id) {
                return new_reference
            }

            // Si l'ancien schema est supprimé
            if ((last_ref['3D-plan']._id && new_reference['3D-plan'] === '') ||
                (last_ref['3D-plan']._id && !new_reference['3D-plan']._id)) {
                if (last_ref['3D-plan'].file) {
                    const file_delete = await deleteFile(last_ref['3D-plan']._id, setAlert);
                    if (!file_delete) return false
                }
                else if (last_ref['3D-plan'].image) {
                    const img_delete = await deleteImage(last_ref['3D-plan']._id, setAlert);
                    if (!img_delete) return false
                }
            }

            // Si il y a un nouveau schema
            if (new_reference['3D-plan'] !== '' && !new_reference['3D-plan']._id) {
                const form = new FormData();

                if (isValidImgExtension(new_reference['3D-plan'])) {
                    form.append('image', new_reference['3D-plan']);

                    const img_id = await postImage(setAlert, form);

                    if (!img_id) return false

                    new_reference['3D-plan'] = img_id;
                }
                else if (isValidFileExtension(new_reference['3D-plan'])) {
                    form.append('file', new_reference['3D-plan']);

                    const file_id = await postFile(setAlert, form);

                    if (!file_id) return false

                    new_reference['3D-plan'] = file_id;
                }
            }

            let body = {};

            Object.keys(new_reference).forEach(head => {
                if (head !== '_id') body[head] = new_reference[head];
            });

            const req = await fetch(`https://api.pro-vide.eu/admin/reference/${reference._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            const res = await req.json();

            // Code d'erreur
            const code = [404, 401, 400];

            // Erreurs classiques
            if (code.includes(req.status)) {
                setAlert({
                    title: 'Une erreur c\'est produite',
                    message: res.message
                });
                return false
            }

            // Erreur serveur
            if (req.status !== 200 || !res) {
                setAlert({
                    title: 'Serveur indisponible',
                    message: res.message
                });
                return false
            }

            // Mise à jour de la state 'references'
            const new_references_db = [...references_db.filter(e => e._id !== reference._id), { _id: reference._id, table: body }];
            dispatch(addReferencesToState(new_references_db));
            return true
        }

        // Creation reference
        if (!reference._id) {
            if (reference['3D-plan'] !== '') {
                const form = new FormData();

                if (isValidImgExtension(reference['3D-plan'])) {
                    form.append('image', reference['3D-plan']);

                    const img_id = await postImage(setAlert, form);

                    if (!img_id) return false

                    new_reference['3D-plan'] = img_id;
                }
                else if (isValidFileExtension(reference['3D-plan'])) {
                    form.append('file', reference['3D-plan']);

                    const file_id = await postFile(setAlert, form);

                    if (!file_id) return false

                    new_reference['3D-plan'] = file_id;
                }
            }

            const req = await fetch(`https://api.pro-vide.eu/admin/reference`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(new_reference)
            });
            const res = await req.json();

            // Code d'erreur
            const code = [404, 401, 400];

            // Erreurs classiques
            if (code.includes(req.status)) {
                setAlert({
                    title: 'Une erreur c\'est produite',
                    message: res.message
                });
                return false
            }

            // Erreur serveur
            if (req.status !== 201 || !res) {
                setAlert({
                    title: 'Serveur indisponible',
                    message: res.message
                });
                return false
            }

            // Mise à jour de la state 'references'
            const new_references_db = [...references_db, { _id: reference._id, table: new_reference }];
            dispatch(addReferencesToState(new_references_db));
            return true
        }
    };

    const formatFilename = (name) => {

        // Supprime tout ce qui se trouve entre 'https' et 'plans/'
        const regex1 = /https.*?plans\//;
        let result = name.replace(regex1, '');

        // Supprime tout ce qui se trouve entre 'https' et 'images/'
        const regex2 = /https.*?images\//;
        result = result.replace(regex2, '');

        // Extrait l'extension du fichier
        const regexExtension = /\.([^.]+)$/;
        const match = result.match(regexExtension);
        const extension = match ? match[1] : '';

        // Supprime les numéros aléatoires à la fin du nom de fichier
        if (extension) {
            const regex2 = new RegExp(`-\\d+\\.${extension}$`);
            result = result.replace(regex2, `.${extension}`);
        }

        return result;
    }


    return formData && (
        <div className='modal modal_update_references'>
            <h1>Modifier une référence</h1>

            <form onSubmit={(event) => submitForm(event)}>
                <div className="ctn_search">
                    <div className="ctn_search_bar">
                        <h2>Références :</h2>

                        <div className="search_bar">
                            <input
                                type="text"
                                value={search}

                                placeholder="Recherche par nom : 'KF16SVCR'"
                                onChange={(e) => setSearch(e.target.value)}
                            />

                            <i className="fas fa-magnifying-glass"></i>
                        </div>

                        {searchList.length !== 0 &&
                            <ul className="search_list">
                                {searchList.map((ref, idx) =>
                                    <li key={idx}>
                                        <p>{ref.table.References}</p>

                                        <button
                                            type='button'
                                            onClick={() => addRef(ref)}
                                        >
                                            <i className="fas fa-share"></i>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                </div>


                <div className="ctn_table_ref">
                    <h2>Créer plusieurs références à la fois :</h2>

                    <div className="table_ref">
                        <ul>
                            {formData.head.map((head, idx) => (
                                <li key={idx}>
                                    {head === 'References' || head === '3D-plan' ? (
                                        <input
                                            type="text"
                                            value={head}
                                            readOnly
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value={head}
                                            placeholder='En-tête'
                                            onChange={(e) => handleHead(e, idx)}
                                        />
                                    )}

                                    {head !== 'References' && head !== '3D-plan' && (
                                        <button
                                            type='button'
                                            className='btn_del_row'
                                            onClick={() => delColumn(head)}
                                        >
                                            <img src={trash} alt=" " />
                                        </button>
                                    )}
                                </li>
                            ))}
                            <button
                                type='button'
                                className='btn_add_column'
                                onClick={() => addColumn()}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </ul>

                        {formData.references.map((ref, idx) => (
                            <ul key={idx}>
                                {formData.head.map((head, id) => (
                                    <li key={id}>
                                        {head !== '3D-plan' &&
                                            <input
                                                type="text"
                                                value={ref[head]}
                                                placeholder={`"${head}"`}
                                                onChange={(e) => handleValue(e, idx, head)}
                                            />
                                        }

                                        {head === '3D-plan' &&
                                            <div className="input_schema">
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleSchema(e, idx, head)}
                                                />

                                                {!ref[head].name && !ref[head].image && !ref[head].file && <label>+ Ajouter un schéma</label>}

                                                {ref[head]?.image && <label>{formatFilename(ref[head]?.image)}</label>}
                                                {ref[head]?.file && <label>{formatFilename(ref[head]?.file)}</label>}

                                                {ref[head]?.name && <label>{ref[head]?.name}</label>}

                                                {(ref[head]?.name || ref[head]?.image || ref[head]?.file) &&
                                                    <button
                                                        type='button'
                                                        className='btn_del_row'
                                                        onClick={() => delSchema(idx, head)}
                                                    >
                                                        <img src={trash} alt=" " />
                                                    </button>
                                                }
                                            </div>
                                        }

                                        {head === 'References' && (
                                            <button
                                                type='button'
                                                className='btn_del_row'
                                                onClick={() => delRef(idx)}
                                            >
                                                <img src={trash} alt=" " />
                                            </button>
                                        )}
                                    </li>
                                ))}

                                <i type='button' className='btn_add_column'></i>
                            </ul>
                        ))}

                    </div>
                    <button
                        type='button'
                        className='btn_add_row'
                        onClick={() => addRow()}
                    >Ajouter une nouvelle ligne</button>
                </div>

                <button className='btn_validation'>
                    Modifier les références
                </button>
            </form>

            {alert && (
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpdateReferences;