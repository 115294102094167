import "./style.css"

import icon_inp_img from './../../assets/svg/svg_image_sombre.svg';
import icon_trash_img from './../../assets/svg/svg_trash_image.svg';
import { useState } from "react";

function InputImages({ formData, setFormData, placeholder }) {
    const [counter, setcounter] = useState(0);

    // Ajout / Modification d'une image
    const handleFile = (e) => {
        const file = e.target.files[0];
        let newFormImg = [ ...formData.images ];

        const previewUrl = URL.createObjectURL(file);

        newFormImg[counter] = {
            value: file || '',
            prev: previewUrl || null
        }

        setFormData({
            ...formData,
            images: newFormImg
        });

        setcounter(counter !== 8 ? counter + 1 : counter);
    }

    // Suppression d'une image
    const deleteImage = () => {
        let newFormImg = [ ...formData.images ];

        newFormImg[counter] = {
            value: '',
            prev: null
        }

        setFormData({
            ...formData,
            images: newFormImg
        });
    }

    return (
        <div className='inp_multi_images'>
            <label>Images {placeholder} :</label>

            <div className="input_file">
                <input type="file" onChange={(e) => handleFile(e)} />

                {formData.images[counter].prev &&
                    <button
                        type='button'
                        className='btn_del_image'
                        onClick={() => deleteImage()}
                    >
                        <img src={icon_trash_img} alt=" " />
                    </button>
                }

                {formData.images[counter].prev && <img className='preview' src={formData.images[counter].prev} alt='Non supporté' />}

                {!formData.images[counter].prev && <img className='icon' src={icon_inp_img} alt=" " />}

                {!formData.images[counter].prev &&
                    <div className="placeholder">
                        <p>Ajouter une image</p>
                        <span>(.jpg, .png, .webp)</span>
                    </div>
                }
            </div>

            <ul className="images_list">
                <li className='prev' onClick={() => setcounter(counter !== 0 ? counter - 1 : counter)}></li>
                
                {formData.images.map((img, idx) => 
                    <li 
                        key={idx}
                        onClick={() => setcounter(idx)}
                        style={{ transform: `translateX(-${counter !== 0 ? 115 * counter : 0}px)` }}
                    >{img.prev && <img src={img.prev} alt="Non supporté" />}</li>
                )}
            </ul>
        </div>
    )
}

export default InputImages;