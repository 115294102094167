import ModalSelector from "../../selectors/Modal";

import ModalServices from "../../modal/ModalServices";
import CreateServices from "../../modalCreate/CreateServices";
import UpdateServices from "../../modalUpdate/UpdateServices";
import DeleteServices from "../../modalDelete/DeleteServices";
import ShowServices from "../../modalShow/ShowServices";

import "./style.css"

function Services({ process, setProcess }) {
    return (
        <div className='page page_services'>
            <ModalSelector process={process} setProcess={setProcess} page='service' un='un' />

            {process.modal === 'selector' && <ModalServices process={process} setProcess={setProcess} />}
            {process.modal === 'add' && <CreateServices process={process} setProcess={setProcess} />}
            {process.modal === 'edit' && <UpdateServices process={process} setProcess={setProcess} />}
            {process.modal === 'delete' && <DeleteServices process={process} setProcess={setProcess} />}
            {process.modal === 'show' && <ShowServices process={process} setProcess={setProcess} />}
        </div>
    )
}

export default Services;