import { useSelector } from "react-redux";

import InputList from "../InputList";

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function BenefitsAndFeatures({ formData, setFormData }) {
    const lang = useSelector(state => state.user.lang);

    const addRow = (array) => {
        setFormData({
            ...formData,
            [array]: [...formData[array], '']
        })
    }

    const delRow = (array) => {
        setFormData({
            ...formData,
            [array]: [...formData[array].slice(0, -1)]
        })
    }

    const addFile = (e) => {
        const file = e.target.files[0];

        const arrayFiles = [
            ...formData.files[lang],
            file
        ];

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const deleteFile = (idx) => {
        const arrayFiles = formData.files[lang].filter((e, id) => id !== idx);

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const formatFilename = (name) => {
        
        // Supprime tout ce qui se trouve entre 'https' et 'plans/'
        const regex1 = /https.*?plans\//;
        let result = name.replace(regex1, '');

        // Extrait l'extension du fichier
        const regexExtension = /\.([^.]+)$/;
        const match = result.match(regexExtension);
        const extension = match ? match[1] : '';

        // Supprime les numéros aléatoires à la fin du nom de fichier
        if (extension) {
            const regex2 = new RegExp(`-\\d+\\.${extension}$`);
            result = result.replace(regex2, `.${extension}`);
        }

        return result;
    }

    return (
        <div className="form_part part_2">
            <div className="list benefits">
                <h2>Avantages :</h2>

                <ul>
                    {formData[`benefits_${lang}`].map((e, idx) =>
                        <InputList key={idx} placeholder='Avantage du produit' formData={formData} setFormData={setFormData} idx={idx} field={`benefits_${lang}`} />
                    )}
                </ul>

                <div className="panel_list">
                    <button
                        type='button'
                        className='add'
                        onClick={() => addRow(`benefits_${lang}`)}
                    >Ajouter</button>

                    <button
                        type='button'
                        className='del'
                        onClick={() => delRow(`benefits_${lang}`)}
                    >Retirer</button>
                </div>
            </div>

            <div className="list features">
                <h2>Caractéristiques :</h2>

                <ul>
                    {formData[`features_${lang}`].map((e, idx) =>
                        <InputList key={idx} placeholder='Caractéristique du produit' formData={formData} setFormData={setFormData} idx={idx} field={`features_${lang}`} />
                    )}
                </ul>

                <div className="panel_list">
                    <button
                        type='button'
                        className='add'
                        onClick={() => addRow(`features_${lang}`)}
                    >Ajouter</button>

                    <button
                        type='button'
                        className='del'
                        onClick={() => delRow(`features_${lang}`)}
                    >Retirer</button>
                </div>
            </div>

            <div className="input_files">
                <h2>Fichiers téléchargeable :</h2>

                <div className="inp_file">
                    <input
                        type="file"
                        onChange={(e) => addFile(e)}
                    />
                    <p>+ Ajouter un fichier <span>(.step, .pdf, dwg, .dxf, .igs)</span></p>
                </div>

                <ul className='files_list'>
                    {formData.files[lang] && formData.files[lang].length !== 0 && formData.files[lang].map((file, idx) =>
                        <li key={idx}>
                            <p>{file._id ? formatFilename(file.name) : file.name}</p>

                            <button
                                type='button'
                                className='btn_del_file'
                                onClick={() => deleteFile(idx)}
                            >
                                <img src={trash} alt=" " />
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default BenefitsAndFeatures;