import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReferencesToState, setLoader } from '../../redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';

import "./style.css";

import trash from './../../assets/svg/svg_trash_red.svg';

const init_form = {
    head: ['References', '3D-plan'],
    references: Array.from({ length: 4 }, () => ({ References: '', '3D-plan': '' }))
};

function CreateReferences() {
    const dispatch = useDispatch();
    const postFile = usePostFile();
    const postImage = usePostImage();

    const token = useSelector(state => state.user.token);
    const references_db = useSelector(state => state.data.references);

    const [formData, setFormData] = useState(init_form);
    const [alert, setAlert] = useState(null);


    const handleHead = (e, idx) => {
        const value = e.target.value || '';

        if (value === 'References' || value === '3D-plan') return;

        let new_form = { ...formData };
        new_form.head[idx] = value;

        setFormData(new_form);
    };

    const handleValue = (e, id, field) => {
        let new_form = { ...formData };
        new_form.references[id][field] = e.target.value;

        setFormData(new_form);
    };

    const handleSchema = (e, id, field) => {
        const file = e.target.files[0];
        let new_form = { ...formData };

        new_form.references[id][field] = file;
        setFormData(new_form);
    };

    const delSchema = (idx, head) => {
        let new_form = { ...formData };
        new_form.references[idx][head] = '';
        setFormData(new_form);
    };


    const addRow = () => {
        let new_form = { ...formData };
        let new_row = {};

        formData.head.forEach(head => new_row[head] = '');
        new_form.references.push(new_row);

        setFormData(new_form);
    };

    const addColumn = () => {
        let new_form = {
            head: [...formData.head, ''],
            references: [
                ...formData.references
            ]
        };

        for (let i = 0; i < new_form.references.length; i++) {
            new_form.references[i] = { ...new_form.references[i], '': '' };
        }

        setFormData(new_form);
    };

    const delColumn = (head) => {
        const new_form = {
            head: formData.head.filter(e => e !== head),
            references: formData.references.map(ref => {
                const { [head]: _, ...rest } = ref;
                return rest;
            }),
        };

        setFormData(new_form);
    };

    const isValidFileExtension = (file) => {
        const validExtensions = ['.pdf', '.step', '.dwg', '.dxf', '.igs'];
        const extension = validExtensions.find(ext => file.name.toLowerCase().endsWith(ext));

        return !!extension;
    };

    const isValidImgExtension = (file) => {
        const validExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        const response = validExtensions.includes(file.type);

        return response
    };


    const submitForm = async (event) => {
        event.preventDefault();
        dispatch(setLoader(1));

        const response = await Promise.all(formData.references.map(ref => postReference(ref)));

        let new_form = [];

        for (let i = 0; i < response.length; i++) {
            if (!response[i]) new_form.push(formData.references[i]);
        }

        setFormData({ ...formData, references: new_form });

        dispatch(setLoader(-1));

        if (new_form.includes(true)) setAlert({
            title: 'Référence(s) ajouté(s)',
            message: 'La ou les référence(s) on bien été ajouté à la base de donnée'
        });

        if (new_form.includes(false)) setAlert({
            title: 'Référence(s) NON ajouté(s)',
            message: 'Une ou plusieurs référence(s) n\'on pas été ajouté'
        });
    };

    const postReference = async (reference) => {
        let new_reference = {};

        const heads = formData.head.filter(head => head !== '' && head !== '');

        if (reference.References === '' || reference.References === ' ') return null

        heads.map(head => new_reference[head] = reference[head]);

        if (reference['3D-plan'] !== '') {
            const form = new FormData();

            if (isValidImgExtension(reference['3D-plan'])) {
                form.append('image', reference['3D-plan']);

                const img_id = await postImage(setAlert, form);
                
                if (!img_id) return false

                new_reference['3D-plan'] = img_id;
            }
            else if (isValidFileExtension(reference['3D-plan'])) {
                form.append('file', reference['3D-plan']);

                const file_id = await postFile(setAlert, form);
                
                if (!file_id) return false

                new_reference['3D-plan'] = file_id;
            }
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/reference`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(new_reference)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur c\'est produite',
                message: res.message
            });
            return false
        }

        // Erreur serveur
        if (req.status !== 201 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return false
        }

        // Mise à jour de la state 'references'
        const new_references_db = [...references_db, { ...res }];
        dispatch(addReferencesToState(new_references_db));
        return true
    };


    return formData && (
        <div className='modal modal_create_references'>
            <h1>Créer une référence</h1>

            <form onSubmit={(event) => submitForm(event)}>
                <h2>Créer plusieurs références à la fois :</h2>

                <div className="table_ref">
                    <ul>
                        {formData.head.map((head, idx) => (
                            <li key={idx}>
                                {head === 'References' || head === '3D-plan' ? (
                                    <input
                                        type="text"
                                        value={head}
                                        readOnly
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        value={head}
                                        placeholder='En-tête'
                                        onChange={(e) => handleHead(e, idx)}
                                    />
                                )}

                                {head !== 'References' && head !== '3D-plan' && (
                                    <button
                                        type='button'
                                        className='btn_del_row'
                                        onClick={() => delColumn(head)}
                                    >
                                        <img src={trash} alt=" " />
                                    </button>
                                )}
                            </li>
                        ))}
                        <button
                            type='button'
                            className='btn_add_column'
                            onClick={() => addColumn()}
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </ul>

                    {formData.references.map((ref, idx) => (
                        <ul key={idx}>
                            {formData.head.map((head, id) => (
                                <li key={id}>
                                    {head !== '3D-plan' &&
                                        <input
                                            type="text"
                                            value={ref[head]}
                                            placeholder={`"${head}"`}
                                            onChange={(e) => handleValue(e, idx, head)}
                                        />
                                    }

                                    {head === '3D-plan' &&
                                        <div className="input_schema">
                                            <input
                                                type="file"
                                                onChange={(e) => handleSchema(e, idx, head)}
                                            />

                                            <label>{ref[head]?.name ? ref[head].name : '+ Ajouter un schéma'}</label>

                                            {ref[head]?.name &&
                                                <button
                                                    type='button'
                                                    className='btn_del_row'
                                                    onClick={() => delSchema(idx, head)}
                                                >
                                                    <img src={trash} alt=" " />
                                                </button>
                                            }
                                        </div>
                                    }

                                    {head === 'References' && (
                                        <button
                                            type='button'
                                            className='btn_del_row'
                                            onClick={() => {
                                                const newReferences = formData.references.filter((_, i) => i !== idx);
                                                setFormData({ ...formData, references: newReferences });
                                            }}
                                        >
                                            <img src={trash} alt=" " />
                                        </button>
                                    )}
                                </li>
                            ))}

                            <i type='button' className='btn_add_column'></i>
                        </ul>
                    ))}

                    <button
                        type='button'
                        className='btn_add_row'
                        onClick={() => addRow()}
                    >Ajouter une nouvelle ligne</button>
                </div>

                <button className='btn_validation'>
                    Créer les références
                </button>
            </form>

            {alert && (
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CreateReferences;