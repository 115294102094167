import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { useState } from 'react'

import Header from './layouts/Header'

import Home from './pages/Home'
import Error from './pages/Error'
import Login from './pages/Login'

import Categories from './pages/Categories'
import Products from './pages/Products'
import References from './pages/References'
import Services from './pages/Services'
import Achievments from './pages/Achievments'

import ConfirmStop from './components/ConfirmStop'

import './App.css'
import { useSelector } from 'react-redux'
import Loader from './layouts/Loader'

function App() {
    const loader = useSelector(state => state.loader);
    const [process, setProcess] = useState({
        w_modal: 'selector',
        modal: 'selector',
        page: '',

        w_stop: false,
        stop: false
    });

    return process && (
        <React.StrictMode>
            <Router>
                <Header process={process} setProcess={setProcess} />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Error />} />
                    <Route path="/login" element={<Login />} />

                    <Route path="/categories" element={<Categories process={process} setProcess={setProcess} />} />
                    <Route path="/products" element={<Products process={process} setProcess={setProcess} />} />
                    <Route path="/references" element={<References process={process} setProcess={setProcess} />} />
                    <Route path="/services" element={<Services process={process} setProcess={setProcess} />} />
                    <Route path="/achievments" element={<Achievments process={process} setProcess={setProcess} />} />
                </Routes>

                {loader && loader !== 0 && <Loader />}
                {process.w_stop && <ConfirmStop process={process} setProcess={setProcess} />}
            </Router>
        </React.StrictMode>
    )
}

export default App;