import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import PanelControl from '../../components/PanelControl';
import CategorySelector from '../../selectors/Category';

import "./style.css"

import icon_inp_img from './../../assets/svg/svg_image_sombre.svg';
import icon_trash_img from './../../assets/svg/svg_trash_image.svg';

const init_form = {
    type: 'prod',

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    parentId: '',
    image: {
        value: '',
        prev: null,
    }
}

function CreateCategories() {
    const dispatch = useDispatch();
    const postImage = usePostImage();

    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);

    const [formData, setFormData] = useState(init_form);
    const [noParent, setNoParent] = useState(false);
    const [alert, setAlert] = useState(null);
    const [part, setPart] = useState(1);

    const handleImage = (e) => {
        const file = e.target.files[0];

        const previewUrl = URL.createObjectURL(file);

        setFormData({
            ...formData,
            image: {
                value: file || '',
                prev: previewUrl || null
            }
        })
    }

    const deleteImage = () => {
        setFormData({
            ...formData,
            image: {
                value: '',
                prev: null
            }
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom de la catégorie" est requis au bon fonctionnement de votre site'
            });
            return
        }

        if (formData.parentId === '') {
            setNoParent(true)
            return
        }

        await postCategory();
    }

    const postCategory = async () => {
        dispatch(setLoader(1));
        setNoParent(false);

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            type: formData.type,
            online: false,
        }

        // Si il y a un 'parentId' on l'ajoute au corp de la request
        if (formData.parentId !== '') body.parentId = formData.parentId;

        // Si il y a une 'image'
        if (formData.image.value !== '') {

            // Création du formulaire d'image
            const formImage = new FormData();
            formImage.append('image', formData.image.value);

            // Ajout de l'image à la db
            const image = await postImage(setAlert, formImage);

            // Si l'image n'est pas ajouté on stop
            if (!image) {
                dispatch(setLoader(-1));
                return
            }

            // Ajout de 'image._id' au corp de la requete
            body.image = image;
        }

        // Ajoute la catégorie
        const req = await fetch(`https://api.pro-vide.eu/admin/category`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];
        
        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur c\'est produite',
                message: res.message
            });
            return
        }

        // Erreur serveur
        if (req.status !== 201 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return
        }

        // Mise à jour de la state 'categories'
        const new_categories_db = [...categories_db, { ...res, name: res.name_fr }];
        dispatch(addCategoriesToState(new_categories_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form, parentId: formData.parentId });
        setAlert({
            title: 'Catégorie ajoutée',
            message: 'Votre catégorie a bien été ajouté à la base de donnée'
        });
    }


    return formData && part && (
        <div className='modal modal_create_categories'>
            <Lang />

            <h1>Créer une catégorie</h1>

            <form onSubmit={(event) => submitForm(event)}>
                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='de la catégorie' />

                        <div className="input_img">
                            <label>Image de la catégorie :</label>

                            <div className="input_file">
                                <input type="file" onChange={(e) => handleImage(e)} />

                                {formData.image.prev &&
                                    <button
                                        type='button'
                                        className='btn_del_image'
                                        onClick={() => deleteImage()}
                                    >
                                        <img src={icon_trash_img} alt=" " />
                                    </button>
                                }

                                {formData.image.prev && <img className='preview' src={formData.image.prev} alt='Non supporté' />}

                                {!formData.image.prev && <img className='icon' src={icon_inp_img} alt=" " />}

                                {!formData.image.prev &&
                                    <div className="placeholder">
                                        <p>Ajouter une image</p>
                                        <span>(.jpg, .png, .webp)</span>
                                    </div>
                                }
                            </div>

                            <label>Type de catégorie :</label>

                            <div className="type_category">
                                <button
                                    type='button'
                                    className={formData.type === 'prod' ? 'actif' : ''}
                                    onClick={() => setFormData({ ...formData, type: 'prod' })}
                                >Produit</button>

                                <button
                                    type='button'
                                    className={formData.type === 'serv' ? 'actif' : ''}
                                    onClick={() => setFormData({ ...formData, type: 'serv' })}
                                >Service</button>
                            </div>
                        </div>
                    </div>
                }

                {part === 2 && <CategorySelector formData={formData} setFormData={setFormData} placeholder='de la catégorie' />}

                <PanelControl part={part} setPart={setPart} maxPart={2} btnValue='Ajouter la catégorie' />
            </form>

            {noParent &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>Parent non sélectionné</span>
                        <p>Vous n'avez pas choisi de parent à votre nouvelle catégorie. <br /><br />Êtes vous sûre ?</p>

                        <div className="panel">
                            <button type='button' onClick={() => setNoParent(false)}>non</button>
                            <button type='button' onClick={() => postCategory()}>oui</button>
                        </div>
                    </div>
                </div>
            }

            {alert &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateCategories;