import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePostImage } from '../../hooks/usePostImage';
import { usePostFile } from '../../hooks/usePostFile';

import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import SelectProduct from '../../selectors/UpdtProduct';
import ConfirmCancel from '../../components/ConfirmCancel';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import BenefitsAndFeatures from '../../components/BenefitsAndFeatures';
import SelecteAssociated from '../../selectors/Associated';
import References from '../../components/References';
import PanelControl from '../../components/PanelControl';

import "./style.css"
import { addProductsToState, setLoader } from '../../redux';

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {
    _id: '',

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    benefits_fr: Array(8).fill(''),
    benefits_en: Array(8).fill(''),
    benefits_sp: Array(8).fill(''),
    benefits_de: Array(8).fill(''),

    features_fr: Array(8).fill(''),
    features_en: Array(8).fill(''),
    features_sp: Array(8).fill(''),
    features_de: Array(8).fill(''),

    references: [],
    ref_descrip_fr: '',
    ref_descrip_en: '',
    ref_descrip_sp: '',
    ref_descrip_de: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    associated: Array(6).fill({ value: '', prev: null }),
    parentId: ''
}

function UpdateProducts() {
    const dispatch = useDispatch();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [cancelUpdt, setCancelUpdt] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [lastData, setLastData] = useState(null);
    const [alertMsg, setAlert] = useState(null);
    const [part, setPart] = useState(0);


    useEffect(() => {
        if (!formData) return
        if (formData._id === '') setPart(0);
    }, [formData])


    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom du produit" est requis au bon fonctionnement de votre site'
            });
            return
        }

        await updateProduct();
    }

    const updateProduct = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            associated: formData.associated,
            references: formData.references,
            ref_descrip_fr: formData.ref_descrip_fr || '',
            ref_descrip_en: formData.ref_descrip_en || '',
            ref_descrip_sp: formData.ref_descrip_sp || '',
            ref_descrip_de: formData.ref_descrip_de || '',

            files: {
                fr: [],
                en: [],
                sp: [],
                de: []
            },

            online: false,
        }

        const images = await updateImages();
        body.images = images;

        body.files.fr = await updateFiles('fr');
        body.files.en = await updateFiles('en');
        body.files.sp = await updateFiles('sp');
        body.files.de = await updateFiles('de');

        // Filtrage des liste 'benefits' & 'features'
        for (let lang of langs) {
            body[`benefits_${lang}`] = formData[`benefits_${lang}`].filter(e => e !== '' && e !== ' ');
            body[`features_${lang}`] = formData[`features_${lang}`].filter(e => e !== '' && e !== ' ');
        }

        // Modifier le service
        const req = await fetch(`https://api.pro-vide.eu/admin/product/${formData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));
        
        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de la modification du produit',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        let new_products_db = [];

        // Mise à jour de la state 'products'
        for (let product of products_db) {
            if (product._id !== formData._id) {
                new_products_db.push(product);
                continue
            };

            new_products_db.push({
                ...body,
                _id: formData._id,
                name: body.name_fr,
                parentId: formData.parentId
            })
        }

        dispatch(addProductsToState(new_products_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form });
        setAlert({
            title: 'Produit modifié',
            message: 'Votre produit a bien été modifié'
        });
    }

    const updateImages = async () => {
        let response = [];

        for (let i = 0; i < formData.images.length; i++) {
            const image_last = lastData.images[i] || null;
            const image = formData.images[i];

            // Si l'image reste inchangé
            if (image._id) {
                response.push(image._id);
                return
            }

            // Si il n'y a pas et n'avait pas d'image
            if (!image_last._id && !image._id && image.value === '') continue

            // Si l'ancienne image a été supprimé
            if (image_last._id && !image._id) {
                const image_delete = await deleteImage(image_last._id, setAlert);

                if (!image_delete) {
                    setAlert({
                        title: 'Problème d\'image',
                        message: `Un problème est survenue lors de la suppression de la précédente image n° ${i}'`
                    });
                    response.push(image_last._id);
                    continue
                }
                if (image.value === '') continue
            }

            // Si une nouvelle image est ajouté
            if (image.value !== '') {
                const formImage = new FormData();
                formImage.append('image', formData.images[i].value);

                // Ajout de l'image à la db
                const image_id = await postImage(setAlert, formImage);

                // Si l'image n'est pas ajouté on continue
                if (!image_id) {
                    setAlert({
                        title: 'Problème d\'image',
                        message: `Un problème est survenue lors de la ajout de l'image n° ${i}`
                    });
                    continue
                }
                response.push(image_id)
            }
        }

        return response
    }

    const updateFiles = async (lg) => {
        let response = []

        const files = formData.files[lg];
        const last_files = lastData.files[lg];

        // Suppression de tous les fichiers à retirer
        for (let i = 0; i < last_files.length; i++) {
            if (!files.includes(last_files[i])) {
                const file_delete = await deleteFile(last_files[i]._id, setAlert);

                if (!file_delete) {
                    setAlert({
                        title: 'Problème de fichier',
                        message: `Un problème est survenue lors de la suppression du précédent fichier n° ${i} version '${lg}'`
                    });
                    response.push(last_files[i]._id);
                    continue
                }
            }
        }

        // Ajout des images pré-existante & Ajout de nouveau fichiers
        for (let i = 0; i < files.length; i++) {
            if (files[i]._id) {
                response.push(files[i]._id);
                continue
            }

            const formFile = new FormData();
            formFile.append('file', formData.files[lg][i]);

            const file_id = await postFile(setAlert, formFile);
            response.push(file_id);
        }

        return response
    }


    return (
        <div className='modal modal_update_products'>
            <Lang />

            <h1>Modifier un produit</h1>

            {part !== 0 && !cancelUpdt &&
                <button
                    className='btn_stop_update'
                    onClick={() => setCancelUpdt(true)}
                >Annuler</button>
            }

            {part !== 0 && cancelUpdt && <ConfirmCancel init_form={init_form} setFormData={setFormData} setLastData={setLastData} setCancelUpdt={setCancelUpdt} />}

            <form onSubmit={(even) => submitForm(even)}>
                {part === 0 && <SelectProduct formData={formData} setFormData={setFormData} setLastData={setLastData} setPart={setPart} />}

                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='du produit' />
                        {formData.images && <InputImages formData={formData} setFormData={setFormData} placeholder='du produit' />}
                    </div>
                }

                {part === 2 && <BenefitsAndFeatures formData={formData} setFormData={setFormData} />}

                {part === 3 && <SelecteAssociated formData={formData} setFormData={setFormData} />}

                {part === 4 && <References formData={formData} setFormData={setFormData} />}

                {part !== 0 && <PanelControl part={part} setPart={setPart} maxPart={4} btnValue='Modifier le produit' />}
            </form>

            {alertMsg &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateProducts;