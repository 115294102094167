import Lottie from "lottie-react";

import Astronaute from './Animation - 1736463796323.json';

const style = {
    
};

function LottieLogin() {
    return (
        <div style={style} className="lottie_login">
            <Lottie animationData={Astronaute} />
        </div>
    )

}

export default LottieLogin;
