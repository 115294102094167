import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePostImage } from '../../hooks/usePostImage';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import CategorySelector from '../../selectors/Category';
import PanelControl from '../../components/PanelControl';

import "./style.css"
import { addAchievmentsToState, setLoader } from '../../redux';

const init_form = {

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',
    
    images: Array(9).fill({ value: '', prev: null }),

    parentId: '',
}

function CreateAchievments() {
    const dispatch = useDispatch();

    const postImage = usePostImage();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const achievments_db = useSelector(state => state.data.services);

    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState(null);
    const [part, setPart] = useState(1);

    
    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom de la réalisation" est requis au bon fonctionnement de votre site'
            });
            return
        }

        if (formData.parentId === '') {
            setAlert({
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            });
            return
        }

        await postService();
    }

    const postService = async () => {
        dispatch(setLoader(1));
        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            images: [],

            parentId: formData.parentId,
            online: false,
        }

        // Si il y a des 'images'
        body.images = await postImages();
        if (body.images === 'error') {
            dispatch(setLoader(-1));
            return
        }

        // Ajoute la réalisation
        const req = await fetch(`https://api.pro-vide.eu/admin/achievement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) {
            setAlert({
                title: 'Une erreur c\'est produite',
                message: res.message
            });
            return
        }

        // Erreur serveur
        if (req.status !== 201 || !res) {
            setAlert({
                title: 'Serveur indisponible',
                message: res.message
            });
            return
        }

        // Mise à jour de la state 'products'
        const new_achievments_db = [...achievments_db, { ...res, name: res.name_fr }];
        dispatch(addAchievmentsToState(new_achievments_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form, parentId: formData.parentId });
        setAlert({
            title: 'Réalisation ajouté',
            message: 'Votre réalisation a bien été ajouté à la base de donnée'
        });
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            // Création du formulaire d'image
            const formImage = new FormData();
            formImage.append('image', img.value);

            // Ajout de l'image à la db
            const image = await postImage(setAlert, formImage);

            // Si l'image n'est pas ajouté on stop
            if (!image) {

                // Suppression des images précédente
                for (let _id of response) {
                    const img_delete = await deleteImage(_id, setAlert);

                    // Si erreur lors de la suppression des images
                    if (!img_delete) {
                        alertMsg('Erreur multiple, contactez le support du site');
                        return 'error'
                    }
                }
                return 'error'
            }

            // Ajout de 'image._id' à 'images' dans le corp de la requete
            response.push(image);
        }

        return response
    }


    return (
        <div className='modal modal_create_achievments'>
            <Lang />

            <h1>Créer une réalisation</h1>

            <form onSubmit={(event) => submitForm(event)}>
                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                        <InputImages formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                    </div>
                }

                {part === 2 && <CategorySelector formData={formData} setFormData={setFormData} placeholder='de la réalisation' lock={true} />}

                <PanelControl part={part} setPart={setPart} maxPart={2} btnValue='Ajouter la réalisation' />
            </form>

            {alertMsg &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateAchievments;