import "./style.css"

function ShowServices() {

    return (
        <div className='modal modal_show_services'>
            <h1>Changer l'ordre des services</h1>

            <form>

            </form>
        </div>
    )
}

export default ShowServices;