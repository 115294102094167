import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import SelectCategory from '../../selectors/UpdtCategory';
import ConfirmCancel from '../../components/ConfirmCancel';
import NameAndText from '../../components/NameAndText';
import PanelControl from '../../components/PanelControl';

import "./style.css"

import icon_inp_img from './../../assets/svg/svg_image_sombre.svg';
import icon_trash_img from './../../assets/svg/svg_trash_image.svg';

const init_form = {
    _id: '',

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    parentId: '',
    image: {
        value: '',
        prev: null,
    }
}

function UpdateCategories() {
    const dispatch = useDispatch();
    const postImage = usePostImage();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);

    const [cancelUpdt, setCancelUpdt] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [lastData, setLastData] = useState(null);
    const [alert, setAlert] = useState(null);
    const [part, setPart] = useState(0);


    useEffect(() => {
        if (!formData) return
        if (formData._id === '') setPart(0);
    }, [formData])


    const handleFile = (e) => {
        const file = e.target.files[0];

        const previewUrl = URL.createObjectURL(file);

        setFormData({
            ...formData,
            image: {
                value: file || '',
                prev: previewUrl || null
            }
        })
    }

    const delImage = () => {
        setFormData({
            ...formData,
            image: {
                value: '',
                prev: null
            }
        })
    }


    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom de la catégorie" est requis au bon fonctionnement de votre site'
            });
            return
        }

        await updateCategory();
    }

    const updateCategory = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            online: false,
        }

        const image = await updateImage();

        if (image === 'error') {
            dispatch(setLoader(-1));
            return
        }

        if (image || image === '') body.image = image;

        // Modifier la catégorie
        const req = await fetch(`https://api.pro-vide.eu/admin/category/${formData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));
        
        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de la modification de la catégorie',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        let new_categories_db = [];
        
        // Mise à jour de la state 'categories'
        for (let category of categories_db) {
            if (category._id !== formData._id) {
                new_categories_db.push(category);
                continue
            };

            new_categories_db.push({
                ...body,
                _id: formData._id,
                name: body.name_fr,
                parentId: formData.parentId || ''
            })
        }

        dispatch(addCategoriesToState(new_categories_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form });
        setAlert({
            title: 'Catégorie modifié',
            message: 'Votre catégorie a bien été modifié'
        });
    }

    const updateImage = async () => {
        const image_last = lastData.image;
        const image = formData.image;

        // Si l'image reste inchangé
        if (image._id) return image._id;

        // Si il n'y a pas et n'avait pas d'image
        if (!image_last._id && !image._id && image.value === '') return false

        // Si l'ancienne image a été supprimé
        if (image_last._id && !image._id) {
            const image_delete = await deleteImage(image_last._id, setAlert);
            
            if (!image_delete) {
                setAlert({
                    title: 'Problème d\'image',
                    message: 'Un problème est survenue lors de la suppression de la précédente image'
                });
                return 'error'
            }
            if (image.value === '') return ''
        }

        // Si une nouvelle image est ajouté
        if (image.value !== '') {
            const formImage = new FormData();
            formImage.append('image', formData.image.value);

            // Ajout de l'image à la db
            const image_id = await postImage(setAlert, formImage);

            // Si l'image n'est pas ajouté on stop
            if (!image_id) {
                setAlert({
                    title: 'Problème d\'image',
                    message: 'Un problème est survenue lors de la ajout de la nouvelle image'
                });
                return 'error'
            }
            return image_id
        }
        return false
    }


    return (
        <div className='modal modal_update_categories'>
            <Lang />

            <h1>Modifier une catégorie</h1>

            {part !== 0 && !cancelUpdt &&
                <button
                    className='btn_stop_update'
                    onClick={() => setCancelUpdt(true)}
                >Annuler</button>
            }

            {part !== 0 && cancelUpdt && <ConfirmCancel init_form={init_form} setFormData={setFormData} setLastData={setLastData} setCancelUpdt={setCancelUpdt} />}

            <form onSubmit={(event) => submitForm(event)}>
                {part === 0 && <SelectCategory formData={formData} setFormData={setFormData} setLastData={setLastData} setPart={setPart} />}

                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='de la catégorie' />

                        <div className="input_img">
                            <label>Image de la catégorie :</label>

                            <div className="input_file">
                                <input type="file" onChange={(e) => handleFile(e)} />

                                {formData.image.prev &&
                                    <button
                                        type='button'
                                        className='btn_del_image'
                                        onClick={() => delImage()}
                                    >
                                        <img src={icon_trash_img} alt=" " />
                                    </button>
                                }

                                {formData.image.prev && <img className='preview' src={formData.image.prev} alt='Non supporté' />}

                                {!formData.image.prev && <img className='icon' src={icon_inp_img} alt=" " />}

                                {!formData.image.prev &&
                                    <div className="placeholder">
                                        <p>Ajouter une image</p>
                                        <span>(.jpg, .png, .webp)</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {part !== 0 && <PanelControl part={part} setPart={setPart} maxPart={1} btnValue='Modifier la catégorie' />}
            </form>

            {alert &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alert.title}</span>
                        <p>{alert.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateCategories;