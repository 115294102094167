export const useGetStorage = () => {
    const getStorage = () => {
        let sessionData = {
            admin_id: sessionStorage.getItem('admin_id') || '',
            admin_pw: sessionStorage.getItem('admin_pw') || '',
            token: sessionStorage.getItem('token') || ''
        };

        let localData = {
            admin_id: localStorage.getItem('admin_id') || '',
            admin_pw: localStorage.getItem('admin_pw') || '',
            token: localStorage.getItem('token') || ''
        };

        if (sessionData.admin_id === '' || sessionData.admin_pw === '' || sessionData.token === '') sessionData = false;
        if (localData.admin_id === '' || localData.admin_pw === '' || localData.token === '') localData = false;

        return {
            session: sessionData,
            local: localData
        };
    };

    return getStorage;
};