// import { useIsNumeric } from "../../hooks/useIsNumeric";

import "./style.css"

function InputLogin({ label, placeholder, value, setValue, field }) {
    // const isNumeric = useIsNumeric();

    // const inputNumber_list = [];

    const updtValue = (e) => {
        let newValue = e.target.value.replace(/[<>{}]/g, '');
        setValue(prev => ({ ...prev, [field]: newValue }));
        return
        
        // if (!inputNumber_list.includes(field) || newValue === '') {
        //     return
        // }
    }

    return (
        <div className="input_login">
            <label>{label}</label>

            <input
                type="text"
                placeholder={placeholder}

                value={value}
                onChange={e => updtValue(e)}
            />
        </div>
    )
}

export default InputLogin;