import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../redux";

export const useDeleteImage = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const deleteImage = async (_id, setAlert) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/del-img/${_id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        return res ? res : false
    };
    return deleteImage;
};
