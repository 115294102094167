import "./style.css"

function ConfirmStop({ process, setProcess }) {
    const handleChange = (res) => {
        if (res) {
            setProcess({ ...process, stop: true });
            return
        }
        setProcess({ ...process, w_stop: false, page: '' });
    }

    return (
        <div className="mdl_stop_process">
            <div className="content">
                <span>Attention</span>
                <p>Vous vous apprétez à annuler la saisie des données <br /><br />Êtes vous sûre ?</p>

                <div className="panel">
                    <button onClick={() => handleChange(false)}>non</button>
                    <button onClick={() => handleChange(true)}>oui</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmStop;