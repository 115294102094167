import "./style.css"

function ConfirmCancel({ init_form, setFormData, setLastData, setCancelUpdt }) {
    const handleChange = (res) => {
        if (res) setFormData(init_form);
        if (res) setLastData(null);
        setCancelUpdt(false);
    }

    return (
        <div className="mdl_cancel_update">
            <div className="content">
                <span>Attention</span>
                <p>Vous vous apprétez à annuler la saisie des données <br /><br />Êtes vous sûre ?</p>

                <div className="panel">
                    <button onClick={() => handleChange(false)}>non</button>
                    <button onClick={() => handleChange(true)}>oui</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmCancel;