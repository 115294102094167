import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../redux";

export const usePostImage = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const postImage = async (setAlert, image) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/add-img`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}` },
            body: image
        });
        const res = await req.json();
        
        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de l\'envoi de votre image',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        return res ? res : null
    };

    return postImage;
};
