import "./style.css"

function ModalAchievments({ process, setProcess }) {
    return (
        <div className='modal modal_achievments'>
            
        </div>
    )
}

export default ModalAchievments;