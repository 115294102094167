import { configureStore, createSlice } from "@reduxjs/toolkit";

// ------------------------------------------------------------------------------------------------
// State utilisateur

const loaderSlice = createSlice({
    name: "loader",
    initialState: 0,
    reducers: {
        setLoader: (state, action) => {            
            return state = state + action.payload;
        }
    }
})

export const { setLoader } = loaderSlice.actions;


// ------------------------------------------------------------------------------------------------
// State utilisateur

const userSlice = createSlice({
    name: "user",
    initialState: {
        admin_id: '',
        admin_pw: '',
        lang: 'fr',
        token: '',
    },
    reducers: {
        login: (state, action) => {
            const { admin_id, admin_pw, token } = action.payload;
            state.admin_id = admin_id || '';
            state.admin_pw = admin_pw || '';
            state.token = token || '';
        },
        logout: (state, action) => {
            state.admin_id = '';
            state.admin_pw = '';
            state.token = '';
        },
        setLang: (state, action) => {            
            state.lang = action.payload;
        }
    }
})

export const { login, logout, setLang } = userSlice.actions;


// ------------------------------------------------------------------------------------------------
// State des données

const dataSlice = createSlice({
    name: "data",
    initialState: {
        categories: [],
        products: [],
        references: [],
        services: [],
        achievments: [],
    },
    reducers: {
        addCategoriesToState: (state, action) => {
            state.categories = action.payload;
        },
        addProductsToState: (state, action) => {
            state.products = action.payload;
        },
        addReferencesToState: (state, action) => {
            state.references = action.payload;
        },
        addServicesToState: (state, action) => {
            state.services = action.payload;
        },
        addAchievmentsToState: (state, action) => {
            state.achievments = action.payload;
        }
    }
})

export const { addCategoriesToState, addProductsToState, addReferencesToState, addServicesToState, addAchievmentsToState } = dataSlice.actions;

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        loader: loaderSlice.reducer,
        user: userSlice.reducer,
        data: dataSlice.reducer,
    }
});