import { useNavigate } from "react-router-dom";

export const useGoPage = () => {
    const navigate = useNavigate();

    const goPage = (route) => {
        window.scrollTo(0, 0);        
        navigate(`${route}`);
    };

    return goPage;
};
