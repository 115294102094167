import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';
import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';

import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import CategorySelector from '../../selectors/Category';
import PanelControl from '../../components/PanelControl';

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';
import { addServicesToState, setLoader } from '../../redux';

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),
    video: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    parentId: '',
}

function CreateServices() {
    const dispatch = useDispatch();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const lang = useSelector(state => state.user.lang);
    const token = useSelector(state => state.user.token);
    const services_db = useSelector(state => state.data.services);

    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState(null);
    const [part, setPart] = useState(1);


    const addFile = (e) => {
        const file = e.target.files[0];

        const arrayFiles = [
            ...formData.files[lang],
            file
        ];

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const delFile = (idx) => {
        const arrayFiles = formData.files[lang].filter((e, id) => id !== idx);

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert({
                title: 'Champ manquant',
                message: 'Le champ "Nom du service" est requis au bon fonctionnement de votre site'
            });
            return
        }

        if (formData.parentId === '') {
            setAlert({
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            });
            return
        }

        await postService();
    }

    const postService = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            video: formData.video,
            images: [],

            parentId: formData.parentId,
            online: false,
        }

        // Si il y a des 'images'
        body.images = await postImages();
        if (body.images === 'error') {
            dispatch(setLoader(-1));
            return
        }

        //Si il y a des 'files'
        body.files = await postFiles();
        if (body.files === 'error') {
            dispatch(setLoader(-1));
            return
        }

        // Ajoute le service
        const req = await fetch(`https://api.pro-vide.eu/admin/service`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401, 400];

        dispatch(setLoader(-1));

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de l\'ajout du service',
            message: res.message
        });

        // Erreur serveur
        if (req.status !== 201 || !res._id) setAlert({
            title: 'Une erreur c\'est produite, serveur indisponible',
            message: res.message
        });

        // Mise à jour de la state 'products'
        const new_services_db = [...services_db, { ...res, name: res.name_fr }];
        dispatch(addServicesToState(new_services_db));

        // Ré-initialisation du formulaire
        setFormData({ ...init_form, parentId: formData.parentId });
        setAlert({
            title: 'Service ajouté',
            message: 'Votre service a bien été ajouté à la base de donnée'
        });
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            // Création du formulaire d'image
            const formImage = new FormData();
            formImage.append('image', img.value);

            // Ajout de l'image à la db
            const image = await postImage(setAlert, formImage);

            // Si l'image n'est pas ajouté on stop
            if (!image) {

                // Suppression des images précédente
                for (let _id of response) {
                    const img_delete = await deleteImage(_id, setAlert);

                    // Si erreur lors de la suppression des images
                    if (!img_delete) {
                        alertMsg('Erreur multiple, contactez le support du site');
                        return 'error'
                    }
                }
                return 'error'
            }

            // Ajout de 'image._id' à 'images' dans le corp de la requete
            response.push(image);
        }

        return response
    }

    const postFiles = async () => {
        let response = {
            fr: [],
            en: [],
            sp: [],
            de: []
        };

        for (let lang of langs) {
            for (let file of formData.files[lang]) {

                // Création du formulaire de fichier
                const formFile = new FormData();
                formFile.append('file', file);

                // Ajout du fichier
                const file_id = await postFile(setAlert, formFile);
                response[lang].push(file_id);

                // Si le fichier n'est pas ajouté
                if (!file_id) {
                    // Suppréssion de tous les fichiers ajouter précédement
                    await deleteLastFiles(response, lang);
                    return 'error'
                }
            }
        }
        return response
    }

    // Suppression des fichiers précédent
    async function deleteLastFiles(response, lastLang) {
        let lock = false;

        // Pour chaque lang
        for (let i = 0; i < langs.length; i++) {
            const lang = langs[i];

            // Si la boucle est finit
            if (lock) continue

            // Pour chaque list de fichier à supprimer
            for (let j = 0; j < response[lang].length; j++) {

                // Si c'est le dernier fichier de la derniere list de fichier à supprimer, on verrouille
                if (j === response[lang].length && lang === lastLang) lock = true;

                const _id = response[lang][j];

                const file_delete = await deleteFile(_id, setAlert);

                // Si erreur lors de la suppression des fichiers
                if (!file_delete) {
                    alertMsg('Erreur multiple, contactez le support du site');
                    return
                }
            }
        }
    }


    return (
        <div className='modal modal_create_services'>
            <Lang />

            <h1>Créer un service</h1>

            <form onSubmit={(event) => submitForm(event)}>
                {part === 1 &&
                    <div className="form_part part_1">
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='du service' />
                        <InputImages formData={formData} setFormData={setFormData} placeholder='du service' />
                    </div>
                }

                {part === 2 &&
                    <div className="form_part part_2">
                        <div className="input_files">
                            <h2>Fichiers téléchargeable :</h2>

                            <div className="inp_file">
                                <input
                                    type="file"
                                    onChange={(e) => addFile(e)}
                                />
                                <p>+ Ajouter un fichier <span>(.step, .pdf, dwg, .dxf, .igs)</span></p>
                            </div>

                            <ul className='files_list'>
                                {formData.files[lang] && formData.files[lang].length !== 0 && formData.files[lang].map((file, idx) =>
                                    <li key={idx}>
                                        <p>{file.name}</p>

                                        <button
                                            type='button'
                                            className='btn_del_file'
                                            onClick={() => delFile(idx)}
                                        >
                                            <img src={trash} alt=" " />
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="input_video">
                            <h2>Lien vidéo :</h2>

                            <input
                                type="text"
                                className='inp_video'
                                placeholder='https://www.youtube.com/watch?v=GkZOF9Wk3Zc&t=50s'

                                value={formData.video}
                                onChange={(e) => setFormData({ ...formData, video: e.target.value })}
                            />
                        </div>
                    </div>
                }

                {part === 3 && <CategorySelector formData={formData} setFormData={setFormData} placeholder='du service' type='serv' />}

                <PanelControl part={part} setPart={setPart} maxPart={3} btnValue='Ajouter le service' />
            </form>

            {alertMsg &&
                <div className="mdl_cancel_update">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreateServices;