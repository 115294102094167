import "./style.css"

function Loader() {
    return (
        <div className='loader'>
            <div className="loader-content">
                <i className="fas fa-spinner"></i>
            </div>
        </div>
    )
}

export default Loader;