import ModalSelector from "../../selectors/Modal";

import ModalProducts from "../../modal/ModalProducts";
import CreateProducts from "../../modalCreate/CreateProducts";
import UpdateProducts from "../../modalUpdate/UpdateProducts";
import DeleteProducts from "../../modalDelete/DeleteProducts";
import ShowProducts from "../../modalShow/ShowProducts";

import "./style.css"

function Products({ process, setProcess }) {
    return (
        <div className='page page_products'>
            <ModalSelector process={process} setProcess={setProcess} page='produit' un='un' />

            {process.modal === 'selector' && <ModalProducts process={process} setProcess={setProcess} />}
            {process.modal === 'add' && <CreateProducts process={process} setProcess={setProcess} />}
            {process.modal === 'edit' && <UpdateProducts process={process} setProcess={setProcess} />}
            {process.modal === 'delete' && <DeleteProducts process={process} setProcess={setProcess} />}
            {process.modal === 'show' && <ShowProducts process={process} setProcess={setProcess} />}
        </div>
    )
}

export default Products;