import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function References({ formData, setFormData }) {
    const [search, setSearch] = useState('');
    const [searchList, setSearchList] = useState([]);

    const lang = useSelector(state => state.user.lang);
    const references_db = useSelector(state => state.data.references);

    useEffect(() => {
        let new_list = [];

        if (!search || search === '') {
            setSearchList(new_list);
            return
        }
        

        // Trouver les references.table.References qui contienne search
        new_list = references_db.filter(ref =>
            ref.table && ref.table.References && typeof ref.table.References === 'string' &&
            ref.table.References.toLowerCase().includes(search.toLowerCase())
        );

        // Limiter le nombre de résultats à 16
        new_list = new_list.slice(0, 16);
    
        setSearchList(new_list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [references_db, search])


    const addRef = (ref) => {
        const check = formData.references.find(_ => _._id === ref._id);

        if (check) return

        const new_list = [ ...formData.references, ref ];
        
        setFormData({
            ...formData,
            references: new_list
        })
    }

    const deleteRef = (_id) => {
        const new_list = formData.references.filter(ref => ref._id !== _id);
        setFormData({
            ...formData,
            references: new_list
        })
    }

    return (
        <div className="form_part part_3">
            <div className="table_ref">
                <div className="ctn_search_bar">
                    <h2>Références :</h2>

                    <div className="search_bar">
                        <input
                            type="text"
                            value={search}

                            placeholder="Recherche par nom : 'KF16SVCR'"
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <i className="fas fa-magnifying-glass"></i>
                    </div>

                    {searchList.length !== 0 &&
                        <ul className="search_list">
                            {searchList.map((ref, idx) => 
                                <li key={idx}>
                                    <p>{ref.table.References}</p>
                                    
                                    <button
                                        type='button'
                                        onClick={() => addRef(ref)}
                                    >
                                        <i className="fas fa-share"></i>
                                    </button>
                                </li>
                            )}
                        </ul>
                    }
                </div>

                <ul className='references_list'>
                    <h2>Liste :</h2>

                    {formData.references.length !== 0 && formData.references.map((ref, idx) => 
                        <li key={idx}>
                            <p>{ref.table.References}</p>

                            <button
                                type='button'
                                className='btn_del_file'
                                onClick={() => deleteRef(ref._id)}
                            ><img src={trash} alt=" " /></button>
                        </li>
                    )}
                </ul>
            </div>

            <div className="textarea">
                <label>Description du tableau de références :</label>

                <textarea
                    placeholder='Description du tableau de références'

                    value={formData[`ref_descrip_${lang}`]}
                    onChange={(e) => setFormData({ ...formData, [`ref_descrip_${lang}`]: e.target.value })}
                ></textarea>
            </div>
        </div>
    )
}

export default References;