import "./style.css"

function ShowProducts() {

    return (
        <div className='modal modal_show_products'>
            <h1>Changer l'ordre des produits</h1>

            <form>

            </form>
        </div>
    )
}

export default ShowProducts;