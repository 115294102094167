import "./style.css"

function Home() {

    return (
        <div className='page page_home'>

        </div>
    )
}

export default Home;