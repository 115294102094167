import "./style.css"

function ModalProducts({ process, setProcess }) {
    return (
        <div className='modal modal_products'>
            
        </div>
    )
}

export default ModalProducts;