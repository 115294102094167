import { useDispatch } from "react-redux";
import { setLoader } from "../redux";

export const useFetchImage = () => {
    const dispatch = useDispatch();

    const fetchImage = async (id) => {
        try {
            dispatch(setLoader(1));

            const req = await fetch(`https://api.pro-vide.eu/public/image/${id}`);
            const res = await req.json();
        
            dispatch(setLoader(-1));

            return req.status !== 200 ? null : res    
        } 
        catch (error) {
            return error.message;            
        }
    };
    return fetchImage;
};
