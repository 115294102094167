import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useFetchFile } from "../../hooks/useFetchFile";
import { useFetchImage } from "../../hooks/useFetchImage";

import "./style.css"

const langs = ['fr', 'en', 'sp', 'de'];

function SelectService({ formData, setFormData, setLastData, setPart }) {
    const goPage = useGoPage();
    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();

    const [parent, setParent] = useState('');
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);

    const token = useSelector(state => state.user.token);
    const services_db = useSelector(state => state.data.services);
    const categories_db = useSelector(state => state.data.categories);

    // Mise à jour affichage des éléments
    useEffect(() => {
        if (!formData || !categories_db) return

        const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'serv');
        setCategories(categories_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mise à jour affichage des éléments
    useEffect(() => {
        if ((!parent && parent !== '') || !categories_db) return

        setFormData({
            ...formData,
            _id: ''
        })

        if (parent === '') {
            const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'serv');
            setCategories(categories_list);
            setServices([]);
            return
        }

        const categories_list = categories_db.filter(cat => cat.parentId === parent);
        setCategories(categories_list);

        if (!categories_list || categories_list.length === 0) {
            const services_list = services_db.filter(prod => prod.parentId === parent);
            setServices(services_list);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent, categories_db, services_db]);

    const previous = () => {
        const cat_parent = categories_db.find(cat => cat._id === parent);
        setParent(cat_parent && cat_parent.parentId ? cat_parent.parentId : '');
        setServices([]);
    }

    const chooseParent = (_id) => {
        setFormData({
            ...formData,
            _id: _id !== formData._id ? _id : ''
        })
    }

    const fetchLastData = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/service/${formData._id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        })
        const res = await req.json();

        // Si probleme de recupération du service
        if (req.status !== 200 || !res) {
            goPage('/error');
            return
        }

        let images = Array(9).fill({
            value: '',
            prev: null
        });

        // Si le service a des images
        if (res.images.length !== 0) {
            for (let i = 0; i < res.images.length; i++) {
                const image_service = await fetchImage(res.images[i]);

                if (image_service) images[i] = {
                    value: '',
                    _id: image_service._id,
                    prev: image_service.image
                }
            }
        }

        const files = await getFiles(res.files);

        // Chargement des données
        setFormData({
            _id: formData._id,

            name_fr: res.name_fr || '',
            name_en: res.name_en || '',
            name_sp: res.name_sp || '',
            name_de: res.name_de || '',

            text_fr: res.text_fr || '',
            text_en: res.text_en || '',
            text_sp: res.text_sp || '',
            text_de: res.text_de || '',

            video: res.video || '',
            images: images,

            files: files,
            parentId: res.parentId || '',
        })
        setLastData({
            _id: formData._id,

            name_fr: res.name_fr || '',
            name_en: res.name_en || '',
            name_sp: res.name_sp || '',
            name_de: res.name_de || '',

            text_fr: res.text_fr || '',
            text_en: res.text_en || '',
            text_sp: res.text_sp || '',
            text_de: res.text_de || '',

            video: res.video || '',
            images: images,

            files: files,
            parentId: res.parentId || '',
        })
        setPart(1);
    }

    async function getFiles(files) {
        let response = {
            fr: [],
            en: [],
            sp: [],
            de: []
        }

        if (!files) return response

        for (let lang of langs) {
            for (let file_id of files[lang]) {
                const file = await fetchFile(file_id);

                if (!file) continue

                response[lang].push({
                    _id: file_id,
                    name: file.file
                })
            }
        }
        return response
    }

    return (
        <div className='service_selector'>
            <h2>Selectionnez un service</h2>

            <ul className="select_list">
                {parent !== '' &&
                    <li className='first'>
                        <button type='button' onClick={() => previous()} >Revenir en arrière</button>
                    </li>
                }

                {categories && categories.length !== 0 && categories.map((cat, idx) =>
                    <li
                        key={idx}
                        className={parent === '' ? 'first' : ''}
                    >
                        <button type='button'>
                            <p onClick={() => setParent(cat._id)}>{cat.name}</p>
                        </button>
                    </li>
                )}

                {services && services.length !== 0 && services.map((prod, idx) =>
                    <li key={idx} className='product'>
                        <button
                            type='button'
                            className={prod._id === formData.parentId ? 'actif' : ''}
                        >
                            <p>{prod.name}</p>
                            <i
                                className='check'
                                onClick={() => chooseParent(prod._id)}
                            >{prod._id === formData._id ? '✓' : ''}</i>
                        </button>
                    </li>
                )}

                {categories.length === 0 && services.length === 0 &&
                    <li>
                        <p className='no_value'>Aucune données</p>
                    </li>
                }
            </ul>

            {formData._id !== '' &&
                <button
                    type='button'
                    className='edit_category'
                    onClick={() => fetchLastData()}
                >Modifier le service</button>
            }
        </div>
    )
}

export default SelectService;