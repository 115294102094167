import "./style.css"

function PanelControl({ part, setPart, maxPart, btnValue, setFormData }) {
    const previous = () => {
        if (part === 1) return
        setPart(part - 1);
    }

    const next = () => {
        if (part === maxPart) return
        setPart(part + 1);
    }
    
    return (
        <div className='panel_control'>
            <button 
                type='button'
                onClick={() => previous()}
                className={maxPart === 1 || part === 1 ? 'invisible' : ''}
            >
                <span>{'<'}</span> Précédement
            </button>

            {part === maxPart &&
                <button className='btn_validation'>
                    {btnValue}
                </button>
            }

            <button 
                type='button'
                onClick={() => next()}
                className={maxPart === 1 || part === maxPart ? 'invisible' : ''}
            >
                Suivant <span>{'>'}</span>
            </button>
        </div>
    )
}

export default PanelControl;