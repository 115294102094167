import ModalSelector from "../../selectors/Modal";

import ModalCategories from "../../modal/ModalCategories";
import CreateCategories from "../../modalCreate/CreateCategories";
import UpdateCategories from "../../modalUpdate/UpdateCategories";
import DeleteCategories from "../../modalDelete/DeleteCategories";
import ShowCategories from "../../modalShow/ShowCategories";

import "./style.css"

function Categories({ process, setProcess }) {
    return (
        <div className='page page_categories'>
            <ModalSelector process={process} setProcess={setProcess} page='catégorie' un='une' />

            {process.modal === 'selector' && <ModalCategories process={process} setProcess={setProcess} />}
            {process.modal === 'add' && <CreateCategories process={process} setProcess={setProcess} />}
            {process.modal === 'edit' && <UpdateCategories process={process} setProcess={setProcess} />}
            {process.modal === 'delete' && <DeleteCategories process={process} setProcess={setProcess} />}
            {process.modal === 'show' && <ShowCategories process={process} setProcess={setProcess} />}
        </div>
    )
}

export default Categories;