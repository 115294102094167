import Lottie from "lottie-react";

import Moon from './Animation - 1736464924628.json';

const style = {
    
};

function LottieLoginMoon() {
    return (
        <div style={style} className="lottie_login_moon">
            <Lottie animationData={Moon} />
        </div>
    )

}

export default LottieLoginMoon;
