import ModalSelector from "../../selectors/Modal";

import ModalAchievments from "../../modal/ModalAchievments";
import CreateAchievments from "../../modalCreate/CreateAchievments";
import UpdateAchievments from "../../modalUpdate/UpdateAchievments";
import DeleteAchievments from "../../modalDelete/DeleteAchievments";
import ShowAchievments from "../../modalShow/ShowAchievments";

import "./style.css"

function Achievments({ process, setProcess }) {
    return (
        <div className='page page_achievments'>
            <ModalSelector process={process} setProcess={setProcess} page='réalisation' un='une' />

            {process.modal === 'selector' && <ModalAchievments process={process} setProcess={setProcess} />}
            {process.modal === 'add' && <CreateAchievments process={process} setProcess={setProcess} />}
            {process.modal === 'edit' && <UpdateAchievments process={process} setProcess={setProcess} />}
            {process.modal === 'delete' && <DeleteAchievments process={process} setProcess={setProcess} />}
            {process.modal === 'show' && <ShowAchievments process={process} setProcess={setProcess} />}
        </div>
    )
}

export default Achievments;