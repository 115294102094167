import "./style.css"

function ShowReferences() {

    return (
        <div className='modal modal_show_categories'>
            <h1>Changer l'ordre des catégories</h1>

            <form>

            </form>
        </div>
    )
}

export default ShowReferences;