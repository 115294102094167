import ModalSelector from "../../selectors/Modal";

import ModalReferences from "../../modal/ModalReferences";
import CreateReferences from "../../modalCreate/CreateReferences";
import UpdateReferences from "../../modalUpdate/UpdateReferences";
import DeleteReferences from "../../modalDelete/DeleteReferences";
import ShowReferences from "../../modalShow/ShowReferences";

import "./style.css"

function References({ process, setProcess }) {
    return (
        <div className='page page_references'>
            <ModalSelector process={process} setProcess={setProcess} page='référence' un='une' />

            {process.modal === 'selector' && <ModalReferences process={process} setProcess={setProcess} />}
            {process.modal === 'add' && <CreateReferences process={process} setProcess={setProcess} />}
            {process.modal === 'edit' && <UpdateReferences process={process} setProcess={setProcess} />}
            {process.modal === 'delete' && <DeleteReferences process={process} setProcess={setProcess} />}
            {process.modal === 'show' && <ShowReferences process={process} setProcess={setProcess} />}
        </div>
    )
}

export default References;